import React from "react";
// import "./ItemGrid.scss";
function DateFormat(props) {
  // convert the date to mm/dd/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date)) {
      return "";
    }

    const options = { year: "numeric", month: "short" };
    const formattedDate = date.toLocaleDateString(undefined, options);

    return formattedDate;
  };

  return <span>{formatDate(props.date)}</span>;
}

export default DateFormat;
