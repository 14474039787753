import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ItemGrid from "../../ItemGrid/ItemGrid";
import CreateList from "../../../HTTPS/CreateList/CreateList";
import { useAppContext } from "../../../../AppContext";
import Loader from "../../Loader/Loader";
import "./AddToList.scss";

function AddToList({ open, onClose, oncloseSideBar }) {
  const { state, dispatch } = useAppContext();
  const [createList, setCreateList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);
  // const [activeList, SetActiveList] = useState("Set an Active List");

  // console.log(state);
  let navigate = useNavigate();
  const navigateToRecruit = () => {
    navigate("/recruit");
  };
  const closeAll = () => {
    dispatch({ type: "RECRUIT_LISTS_STATUS", payload: false });
    dispatch({ type: "OVERLAY_RECRUIT_LISTS_STATUS", payload: false });
    if (state.CANDIDATES_SIDE_STATUS) {
      dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: true });
    } else {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
      dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: false });
      dispatch({ type: "CANDIDATES_SIDE_NAME", payload: "" });
    }
  };
  const activeListHandler = (listName, count, listID) => {
    // SetActiveList(`${listName} (${count})`);
    console.log(listID);
    dispatch({ type: "ACTIVE_LIST_ID", payload: listID });
    dispatch({ type: "ACTIVE_LIST_NAME", payload: `${listName}` });
  };

  const manageBtnHandler = () => {
    navigateToRecruit();
    closeAll();
    updateCandidateList();
  };
  const countIDealLength = (array) => {
    const filteredArray = array.filter(
      (obj) => obj.business_status === "ideal"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const countIMaybeLength = (array) => {
    const filteredArray = array.filter(
      (obj) =>
        obj.business_status === "maybe" || obj.business_status === "pending"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };

  const updateCandidateList = (id) => {
    setLoading(true);
    const url = `${state.HTTP_URL}/list_candicates/${state.ACTIVE_LIST_ID}`;
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    if (state.ACTIVE_LIST_ID) {
      fetchData();
    } else {
      dispatch({
        type: "RECRUIT_CANDIDATES_LIST",
        payload: [],
      });
      setLoading(false);
    }

    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          let candidatesInList = [];

          response.data.data.map((item) =>
            candidatesInList.push(item.candidate)
          );
          // Dispatch action to update RECRUIT_CANDIDATES_LIST
          // setTimeout(() => {
          const countIMaybe = countIMaybeLength(candidatesInList);
          const countIDeal = countIDealLength(candidatesInList);
          dispatch({
            type: "RECRUIT_CANDIDATES_LIST",
            payload: candidatesInList,
          });
          dispatch({
            type: "SELECTED_LIST_ID",
            payload: state.ACTIVE_LIST_ID,
          });
          dispatch({
            type: "SELECTED_LIST_NAME",
            payload: state.ACTIVE_LIST_NAME,
          });
          dispatch({ type: "TAB_LIST_NAME", payload: "pending-list" });
          dispatch({
            type: "ALL_LIST_LENGTH",
            payload: candidatesInList.length,
          });
          dispatch({ type: "MAYBE_LIST_LENGTH", payload: countIMaybe });
          dispatch({ type: "IDEAL_LIST_LENGTH", payload: countIDeal });
          setLoading(false);
          // }, 1000);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }
  };
  // Post Request
  const url = `${state.HTTP_URL}/lists`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  useEffect(() => {
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };

    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  let listsItems;
  if (state.LISTS_LIST.length) {
    listsItems = state.LISTS_LIST.map((item, i) => (
      <ItemGrid
        key={item.id}
        id={item.id}
        title={item.name}
        count={item.candidates_count}
        date={item.created_at}
        clicked={() =>
          activeListHandler(item.name, item.candidates_count, item.id)
        }
        updating={false}
      />
    ));
  }
  // close New List
  const closeNewList = () => {
    setCreateList(false);
  };

  if (createList) {
    return ReactDOM.createPortal(
      <div
        className={`candidate-modal custom-modal addToList p-4 ${
          state.RECRUIT_LISTS_STATUS ? "is-open" : ""
        } ${
          state.OVERLAY_RECRUIT_LISTS_STATUS ? "col-4 over-candidateModal" : ""
        }`}
      >
        <button
          className="reset-btn close-modal position-static mb-4"
          onClick={closeAll}
        >
          <i className="icon-close"></i>
        </button>

        {<CreateList onClose={closeNewList} />}
      </div>,
      document.getElementById("portal")
    );
  }

  return ReactDOM.createPortal(
    <div
      className={`candidate-modal custom-modal addToList p-4 ${
        state.RECRUIT_LISTS_STATUS ? "is-open" : ""
      } ${
        state.OVERLAY_RECRUIT_LISTS_STATUS ? "col-4 over-candidateModal" : ""
      }`}
    >
      <button
        className="reset-btn close-modal position-static mb-4"
        onClick={closeAll}
      >
        <i className="icon-close"></i>
      </button>
      {state.LISTS_LIST.length ? (
        <div className="d-flex justify-content-between align-items-center list-management">
          <div>
            <p className="title m-0 active_list md-font text-uppercase">
              {state.ACTIVE_LIST_NAME === null
                ? "Set an Active List"
                : state.ACTIVE_LIST_NAME}
            </p>
          </div>
          <div className="saved-search-date">
            {state.ACTIVE_LIST_NAME && (
              <button
                className="reset-btn submit-btn dark-btn p-2 date text-uppercase pointer mb-0 "
                onClick={manageBtnHandler}
              >
                Launch List
              </button>
            )}
          </div>
        </div>
      ) : (
        <p>Choose a list to add selected candidates:</p>
      )}
      {loading && <Loader />}
      <hr />
      <div className="create-list">
        <ItemGrid
          title="CREATE NEW LIST"
          type="new-list"
          addNewList={() => setCreateList(true)}
          updating={false}
        />
      </div>
      <div className="lists-overflow">{listsItems}</div>
    </div>,
    document.getElementById("portal")
  );
}

export default AddToList;
