import React, { useState, useEffect } from "react";
import InputGrid from "../../InputGrid/InputGrid";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
import { AddToListReq } from "../../../HTTPS/AddToList/AddToListReq";
import { convertDateFormatShort } from "../../../../helpers/helpers";
import "./SavedSearch.scss";
function SavedSearch(props) {
  const [controlBtns, setControlBtns] = useState(false);
  const [isDeleteMsg, setIsDeleteMsg] = useState(false);
  const [savedSearchId, setSavedSearchId] = useState(0);
  const [savedSearchItems, setSavedSearchItems] = useState([]); // Define savedSearchItems state
  const { state, dispatch } = useAppContext();
  const candaditesList = state.CANDIDATES_LIST;
  const searchesUrl = `${state.HTTP_URL}/searches`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const USER_ID = localStorage.getItem("USER_ID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          searchesUrl,
          {},
          {
            headers: {
              Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
            },
          }
        );
        if (response.status === 200) {
          // console.log(response.data.data.SavedSearches);
          dispatch({
            type: "SAVED_SEARCH_LIST",
            payload: response.data.data.SavedSearches,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [searchesUrl, YOUR_ACCESS_TOKEN, dispatch]);

  useEffect(() => {
    // console.log("in");
    const updatedSavedSearchItems = state.SAVED_SEARCH_LIST.map((search) => (
      <InputGrid
        key={search.id}
        title={search.title}
        date={convertDateFormatShort(search.formatted_created_at)}
        callApi={search.link}
        readOnly={true}
        listId={search.id}
        onSavedOpen={props.onSavedOpen}
        showControlBtns={() => getSavedSearchId(search.id)}
      />
    ));
    setSavedSearchItems(updatedSavedSearchItems);
  }, [state.SAVED_SEARCH_LIST, props.onSavedOpen]);

  const removeSavedSearch = async () => {
    const deleteSearchUrl = `${state.HTTP_URL}/delete_search/${savedSearchId}`;

    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    // Save the new search
    try {
      const response = await axios.post(
        deleteSearchUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      // Dispatch action to update SAVED_SEARCH_NAME
      dispatch({ type: "SAVED_SEARCH_NAME", payload: "Untitled Search" });
      dispatch({ type: "SAVED_SEARCH_STATUS", payload: false });
      setIsDeleteMsg(false);

      const url = `${state.HTTP_URL}/candidate/all?list_id=${state.ACTIVE_LIST_ID}&user_id=${USER_ID}`;
      // Define an async function to fetch the data
      async function fetchData() {
        try {
          const response = await axios.get(url);
          if (response.status === 200) {
            // Dispatch action to update CANDIDATES_LIST
            dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
            console.log(response.data.data);
            // setLoading(false);
          }
        } catch (error) {
          console.error("Error:", error);
          // setLoading(false);
        }
      }

      fetchData();
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }

    // then update the SAVED_SEARCH_LIST
    try {
      const response = await axios.post(
        searchesUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      // Dispatch action to update SAVED_SEARCH_LIST
      dispatch({
        type: "SAVED_SEARCH_LIST",
        payload: response.data.data.SavedSearches,
      });
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  const getSavedSearchId = (id) => {
    setControlBtns(true);
    setSavedSearchId(id);
  };
  const addAllCandidatesToList = async () => {
    let candaditesIDS = [];
    candaditesList.map((candadite) => candaditesIDS.push(candadite.id));
    console.log(candaditesIDS);

    // const selectedEl = e.target.closest(".candidate-container");
    const addToListUrl = `${state.HTTP_URL}/add_to_list`;
    const listID = state.ACTIVE_LIST_ID;
    if (!state.ACTIVE_LIST_ID) {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
      dispatch({ type: "RECRUIT_LISTS_STATUS", payload: true });
    }
    // if (!selectedEl.classList.contains("selected")) {
    const responseData = await AddToListReq(
      addToListUrl,
      JSON.stringify(candaditesIDS),
      listID
      // selectedEl
    );
    console.log("Add", responseData);
    // }
  };

  if (isDeleteMsg) {
    return (
      <div
        className={`saved-search-container sidebar-container ${
          state.CANDIDATES_SIDE_NAME === "saved" && "active"
        }`}
      >
        <h4 className="mid-text font-20 text-uppercase">
          ARE YOU SURE YOU WANT TO DELETE
        </h4>
        <p className="mid-bold-text mt-3">
          <b className="text-uppercase">{state.SAVED_SEARCH_NAME}?</b>
        </p>
        <span className="normal-text red-btn">
          THIS ACTION CAN’T BE UNDONE.
        </span>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="reset-btn p-2 normal-text"
            onClick={() => setIsDeleteMsg(false)}
          >
            Cancel
          </button>
          <button
            className="reset-btn submit-btn red-btn p-2 normal-text"
            onClick={removeSavedSearch}
          >
            DELETE
          </button>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`saved-search-container sidebar-container ${
        state.CANDIDATES_SIDE_NAME === "saved" && "active"
      }`}
    >
      <div className={`list-control ${controlBtns ? "visible" : "hidden"}`}>
        <div className={`d-flex align-items-center justify-content-between`}>
          <button
            className="reset-btn normal-text"
            onClick={addAllCandidatesToList}
          >
            ADD ALL
          </button>
          {/* <span></span> */}
          <button
            className="reset-btn submit-btn red-btn p-2 normal-text"
            onClick={() => setIsDeleteMsg(true)}
          >
            DELETE
          </button>
        </div>
      </div>
      <hr className="my-4" />
      {savedSearchItems}
    </div>
  );
}

export default SavedSearch;
