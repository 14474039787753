import React, { useState, Fragment } from "react";
import CandidateControlBar from "../../Shared/CandidateControlBar/CandidateControlBar";
import Profile from "../../Shared/Profile/Profile";
import "./CandidateProfileData.scss";

function CandidateProfileData({ type, CandidateDataObj }) {
  // console.log(CandidateDataObj);

  // let videoChecker;
  // if (localStorage.getItem("isVideo") === "true") {
  //   videoChecker = true;
  // } else {
  //   videoChecker = false;
  // }
  const [isVideo, setIsVideo] = useState(false);
  // change display src
  const setDisplaySrc = (type, e) => {
    if (type === "video") {
      setIsVideo(true);
      // localStorage.setItem("isVideo", "true");
    } else {
      setIsVideo(false);
      // localStorage.setItem("isVideo", "false");
      console.log(e.target);
      setTimeout(() => {
        document.querySelector(".full-image").src = e.target.dataset.src;
      }, 100);
    }
  };
  // candidate data
  const candidateData = (
    <>
      <div className="main-side text-center display-item d-flex align-items-center h-100">
        {!isVideo ? (
          <img
            className="full-image"
            src={CandidateDataObj.photo2}
            alt="candidate Person"
          />
        ) : (
          // <iframe
          //   className="full-video"
          //   width="528"
          //   height="315"
          //   src={CandidateDataObj.video}
          //   title="YouTube video player"
          //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          //   allowFullScreen
          // ></iframe>
          <video width="400" controls>
            <source src={CandidateDataObj.video} type="video/mp4"></source>
            Your browser does not support HTML video.
          </video>
        )}
      </div>
      <div className="thumbnails-side">
        <div className="d-flex m-0 flex-column align-items-center justify-content-center h-100">
          <div className="mb-3 p-0">
            <img
              src={CandidateDataObj.photo2}
              data-src={CandidateDataObj.photo2}
              alt="candidate fullbody"
              onClick={(e) => setDisplaySrc(null, e)}
            />
          </div>
          <div className="mb-3 p-0">
            <img
              src={CandidateDataObj.photo1}
              data-src={CandidateDataObj.photo1}
              alt="candidate face"
              onClick={(e) => setDisplaySrc(null, e)}
            />
          </div>
          <div className="mb-3 p-0">
            <img
              src={CandidateDataObj.photo3}
              data-src={CandidateDataObj.photo3}
              alt="candidate side"
              onClick={(e) => setDisplaySrc(null, e)}
            />
          </div>
          <div
            className="p-0 video-item"
            onClick={(e) => setDisplaySrc("video")}
          >
            <i className="icon-circle-play-regular"></i>
            <img src={CandidateDataObj.photo3} alt="video" />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className={`candidate-profile-data`}>
      <div className="row modal-content-container m-0">
        <div className="col-9 position-relative">
          <div className="candidate-shape row justify-content-center">
            <div className="col-9 images-container justify-content-center h-100 d-flex">
              {candidateData}
            </div>
          </div>
        </div>
        <div className="col-3 p-0">
          <div className="candidate-info-container px-4 py-5">
            <Profile data={CandidateDataObj} type={type} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateProfileData;
