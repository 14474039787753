import React from "react";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import Contacts from "./Contacts/Contacts";
import Fields from "./Fields/Fields";
import WorkExperience from "./WorkExperience/WorkExperience";
import Education from "./Education/Education";
import Documents from "./Documents/Documents";
import "./Profile.scss";

function Profile({ data, type }) {
  // console.log(data);
  return (
    <>
      {data ? (
        <>
          <PersonalInfo data={data} type={type} />
          {type === "candidate" && <Contacts data={data} />}
          <Fields data={data.candidate_activity} />
          <WorkExperience title="WORK EXPERIENCE" data={data.work_experience} />
          <Education title="EDUCATION" data={data.education} />
          <Documents title="Documents" data={data.documents} />
        </>
      ) : null}
    </>
  );
}

export default Profile;
