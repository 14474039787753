import React, { useState } from "react";
import SetName from "../../Shared/SetName/SetName";
import { getSiblings } from "../../../helpers/helpers";
import { useAppContext } from "../../../AppContext";
import axios from "axios";
import "./InputGrid.scss";
function InputGrid(props) {
  const { state, dispatch } = useAppContext();
  const [edit, setEdit] = useState(false);
  const [listName, setListName] = useState("");
  const ACTIVE_LIST_ID = `${state.ACTIVE_LIST_ID}`;

  const toggleActiveClass = (e) => {
    let el;
    e.currentTarget ? (el = e.currentTarget) : (el = e);
    let siblings = getSiblings(el);
    el.classList.add("active");
    siblings.map((e) => e.classList.remove("active"));
  };

  const editListHandler = (e) => {
    let parent = e.target.parentNode;
    let listNameVal = parent.querySelector("input").value;
    setEdit(true);
    setListName(listNameVal);
  };
  const selectItemHandler = (e) => {
    toggleActiveClass(e);
    updateCandidateList();
    props.showControlBtns();
    // Dispatch action to update SAVED_SEARCH_STATUS
    dispatch({ type: "SAVED_SEARCH_STATUS", payload: true });
    dispatch({ type: "SAVED_SEARCH_NAME", payload: props.title });
  };
  const updateCandidateList = () => {
    let url = props.callApi;
    if (ACTIVE_LIST_ID !== "null") {
      url = `${props.callApi}list_id=${ACTIVE_LIST_ID}`;
    }
    console.log(url);
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          // Dispatch action to update CANDIDATES_LIST
          dispatch({
            type: "CANDIDATES_LIST",
            payload: response.data.data,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  };
  if (edit) {
    return (
      <SetName
        name={listName}
        edit={edit}
        listId={props.listId}
        onSavedOpen={props.onSavedOpen}
        onClose={() => setEdit(false)}
      />
    );
  } else {
    return (
      <div
        className={`input-grid-container ${props.active ? "active" : ""}`}
        onClick={(e) => selectItemHandler(e)}
      >
        <i className="icon-pen-square-solid edit" onClick={editListHandler}></i>
        <div className="d-flex align-items-center justify-content-between mt-2 p-1">
          <input
            className="title input-reset normal-text"
            defaultValue={props.title}
            type="text"
            readOnly={props.readOnly && true}
            list-id={props.listId}
          />
          {props.title && <div className="date normal-text">{props.date}</div>}
        </div>
      </div>
    );
  }
}

export default InputGrid;
