import React, { useEffect, useState } from "react";
import CreateList from "../../../HTTPS/CreateList/CreateList";
import ItemGrid from "../../ItemGrid/ItemGrid";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
import "./Lists.scss";
function Lists() {
  // const [deleteListConfirm, setDeleteListConfirm] = useState(false);
  const [lists, setLists] = useState([]);
  const [moveAll, setMoveAll] = useState(false);
  const [createList, setCreateList] = useState(false);
  const [deleteListMsg, setDeleteListMsg] = useState(false);
  const [controlList, setControlList] = useState(false);
  const { state, dispatch } = useAppContext();

  // Post Request
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  useEffect(() => {
    const url = `${state.HTTP_URL}/lists`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        console.log(response.data.data);

        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [createList, deleteListMsg]);

  // make a request to load list candidates
  // const loadListCandidates = (list) => {};
  let listsItems;
  if (state.LISTS_LIST.length) {
    listsItems = state.LISTS_LIST.map((item, i) => (
      <ItemGrid
        key={item.id}
        title={item.name}
        date={item.created_at}
        id={item.id}
        count={item.candidates_count}
        onSelectList={() => setControlList(true)}
        updating={true}
        setMoveAll={() => setMoveAll(false)}
      />
    ));
  }
  // open delete confirm
  const deleteConfirm = () => {
    setDeleteListMsg(true);
  };

  const deleteListHandler = () => {
    const url = `${state.HTTP_URL}/delete_list/${state.SELECTED_LIST_ID}`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        setLists(response.data.data);
        // Dispatch action to update RECRUIT_CANDIDATES_LIST
        dispatch({
          type: "RECRUIT_CANDIDATES_LIST",
          payload: [],
        });
        setDeleteListMsg(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // close New List
  const closeNewList = () => {
    setCreateList(false);
  };

  const fetchFavoritesCandidates = async () => {
    const url = `${state.HTTP_URL}/favorites`;
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    try {
      const response = await axios.get(url, {
        headers: customHeaders,
      });
      if (response.status === 200) {
        const candidatesArray = response.data.data.map(
          (item) => item.candidate
        );

        // Dispatch action to update RECRUIT_CANDIDATES_LIST with the new array
        dispatch({ type: "RECRUIT_CANDIDATES_LIST", payload: candidatesArray });
        dispatch({
          type: "SELECTED_LIST_NAME",
          payload: "FAVORITES",
        });
        console.log(candidatesArray);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const openSubmitListModal = (e) => {
    if (state.IDEAL_LIST_LENGTH > 0) {
      const url = `${state.HTTP_URL}/list_info/${state.SELECTED_LIST_ID}`;
      axios
        .post(
          url,
          {},
          {
            headers: customHeaders,
          }
        )
        .then((response) => {
          // Dispatch action to update CANDIDATES_CONTAINER_STATUS
          console.log(response.data.data);
          dispatch({ type: "SUBMITED_LIST_INFO", payload: response.data.data });
          dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
          dispatch({ type: "SUBMIT_LIST_STATUS", payload: true });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      alert("Please add at least 1 candidate in Ideal list");
    }
  };
  const showMoveAll = () => {
    // setMoveAll(!moveAll);
    dispatch({ type: "MOVE_ALL_BTN", payload: !state.MOVE_ALL_BTN });
  };
  const moveAllHandler = async (type) => {
    const data = {
      list_id: state.SELECTED_LIST_ID,
    };
    // move all candidates to Maybe list
    if (type === "maybe") {
      const url = `${state.HTTP_URL}/maybe_move_all`;
      try {
        const response = await axios.post(url, data, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          console.log(response.data);
          updateSelectedList("maybe");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // move all candidates to Ideal list
      const url = `${state.HTTP_URL}/ideal_move_all`;
      try {
        const response = await axios.post(url, data, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          console.log(response.data);
          updateSelectedList("ideal");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const countIDealLength = (array) => {
    const filteredArray = array.filter(
      (obj) => obj.business_status === "ideal"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const countIMaybeLength = (array) => {
    const filteredArray = array.filter(
      (obj) =>
        obj.business_status === "maybe" || obj.business_status === "pending"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const updateSelectedList = (type) => {
    const url = `${state.HTTP_URL}/list_candicates/${state.SELECTED_LIST_ID}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          // console.log(response.data.data);
          let candidatesInList = [];

          response.data.data.map((item) =>
            candidatesInList.push(item.candidate)
          );
          // Dispatch action to update RECRUIT_CANDIDATES_LIST
          dispatch({
            type: "RECRUIT_CANDIDATES_LIST",
            payload: candidatesInList.slice(),
          });
          !type && dispatch({ type: "TAB_LIST_NAME", payload: "pending-list" });
          const candaditeItems = document.querySelectorAll(".reset-col");
          for (let i = 0; i < candaditeItems.length; i++) {
            const element = candaditeItems[i];
            if (type === "maybe") {
              element.querySelector(".ideal-list").classList.add("hide");
            } else {
              element.querySelector(".maybe-list").classList.add("hide");
            }
          }
          const countIMaybe = countIMaybeLength(candidatesInList);
          const countIDeal = countIDealLength(candidatesInList);
          dispatch({
            type: "ALL_LIST_LENGTH",
            payload: candidatesInList.length,
          });
          dispatch({ type: "MAYBE_LIST_LENGTH", payload: countIMaybe });
          dispatch({ type: "IDEAL_LIST_LENGTH", payload: countIDeal });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  };
  // render
  if (createList) {
    return (
      <div
        className={`lists-container sidebar-container ${
          state.RECRUIT_SIDE_NAME === "lists" && "active"
        }`}
      >
        <CreateList onClose={closeNewList} />
      </div>
    );
  }
  if (deleteListMsg) {
    return (
      <div
        className={`lists-container sidebar-container ${
          state.RECRUIT_SIDE_NAME === "lists" && "active"
        }`}
      >
        <h4 className="mid-text font-20 text-uppercase red-btn">
          Are you sure?
        </h4>
        <p className="mid-bold-text mt-4">
          <span className="thin-text font-20">You want to delete </span>

          <b className="text-uppercase">{state.SELECTED_LIST_NAME}</b>
          <span className="thin-text font-20"> List?</span>
        </p>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="reset-btn p-2 normal-text"
            onClick={() => setDeleteListMsg(false)}
          >
            Cancel
          </button>
          <button
            className="reset-btn submit-btn red-btn p-2 normal-text"
            onClick={deleteListHandler}
          >
            DELETE
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`lists-container sidebar-container ${
        state.RECRUIT_SIDE_NAME === "lists" && "active"
      }`}
    >
      <div className={`list-control ${controlList ? "visible" : "hidden"}`}>
        <button
          className={`reset-btn mb-4 normal-text ${
            state.SELECTED_LIST_NAME == "FAVORITES" && "hidden"
          } ${state.RECRUIT_CANDIDATES_LIST.length === 0 && "hidden"}`}
          onClick={showMoveAll}
        >
          MOVE ALL
        </button>
        {state.MOVE_ALL_BTN &&
          state.SELECTED_LIST_NAME !== "FAVORITES" &&
          (state.TAB_LIST_NAME === "ideal-list" ? (
            <span
              className="mx-3 normal-text pointer text-uppercase"
              onClick={() => moveAllHandler("maybe")}
            >
              Maybe
            </span>
          ) : state.TAB_LIST_NAME === "maybe-list" ? (
            <span
              className="mx-3 normal-text pointer text-uppercase"
              onClick={() => moveAllHandler("ideal")}
            >
              Ideal
            </span>
          ) : (
            <>
              <span
                className="mx-3 normal-text pointer text-uppercase"
                onClick={() => moveAllHandler("maybe")}
              >
                Maybe
              </span>
              <span
                className="normal-text pointer text-uppercase"
                onClick={() => moveAllHandler("ideal")}
              >
                Ideal
              </span>
            </>
          ))}
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="reset-btn submit-btn red-btn p-2 normal-text"
            onClick={deleteConfirm}
          >
            DELETE
          </button>
          {state.SUBMIT_LIST_BTN && (
            <button
              className="reset-btn submit-btn blue-btn p-2 normal-text"
              onClick={openSubmitListModal}
            >
              SUBMIT LIST
            </button>
          )}
        </div>
      </div>
      <hr className="mt-4 mb-4" />

      <ItemGrid
        title="CREATE NEW LIST"
        type="new-list"
        addNewList={() => setCreateList(true)}
      />
      <ItemGrid
        title="FAVORITES"
        type="favorites"
        favorites={fetchFavoritesCandidates}
      />
      {listsItems}
    </div>
  );
}

export default Lists;
