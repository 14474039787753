import React, { useState, useEffect } from "react";
import ApiTagsDataFetcher from "../../ApiTagsDataFetcher/ApiTagsDataFetcher";
import { useAppContext } from "../../../../AppContext";
import axios from "axios";

import MultiRange from "./MultiRange/MultiRange";
import RangeBar from "./RangeBar/RangeBar";
import SetName from "../../SetName/SetName";
import { AddToListReq } from "../../../HTTPS/AddToList/AddToListReq";
import {
  getSiblings,
  pushOrRemoveFromArray,
  isLastCharacterAmpersand,
} from "../../../../helpers/helpers";
import "./Tags.scss";

// tags value
let levelIDs = [];
let passportParams = [];
let resumeParams = [];
let docsParams = [];
let licenseParams = [];
let genderParams = [];
let industriesIDs = "";
let fieldsIDs = [];
let activitiesIDs = [];
let activityParams = "";
let countriesIDs = [];
let countryParams = "";
let nationalitiesIDs = [];
let nationalityParams = "";
let languagesIDs = [];
let languageParams = "";
let availabilityID = "";
let fromAge = "";
let toAge = "";
let fromHeight = "";
let toHeight = "";
let fieldParams = "";

// SEARCH_PARAMS
let SEARCH_PARAMS = "";
let urlParms = "";
function Tags(props) {
  const [fieldID, setFieldID] = useState(0);
  const [activityID, setActivityID] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isInputsChecked, setIsInputsChecked] = useState(false);
  const { state, dispatch } = useAppContext();
  const candaditesList = state.CANDIDATES_LIST;
  const USER_ID = localStorage.getItem("USER_ID");
  // console.log(candaditesList);
  const toggleActiveClass = (e) => {
    let el;
    e.currentTarget ? (el = e.currentTarget) : (el = e);
    let siblings = getSiblings(el);
    el.classList.add("active");
    siblings.map((e) => e.classList.remove("active"));
  };

  const activeTagsTabHandler = (e) => {
    const qualificationTab = document.querySelector(
      ".qualification-tab-content"
    );
    const personalTab = document.querySelector(".personal-tab-content");
    toggleActiveClass(e);
    // show tabs content
    if (e.target.dataset.type === "qualifications") {
      toggleActiveClass(qualificationTab);
    } else {
      toggleActiveClass(personalTab);
    }
  };

  const cancelSetSaved = () => {
    setIsSaved(false);
  };

  const checkInputsChecker = () => {
    const inputs = document.querySelectorAll("#tagsAccordion input");
    inputs.forEach((input) => {
      if (
        (input.checked && input.type === "radio") ||
        input.type === "checkbox"
      ) {
        setIsInputsChecked(true);
      } else if (input.type === "range") {
        setIsInputsChecked(true);
      }
    });
  };
  const clearInputsChecker = () => {
    resetAllParams();
    const accordionItem = document.querySelectorAll(
      "#tagsAccordion .accordion-item"
    );
    const inputs = document.querySelectorAll("#tagsAccordion input");
    accordionItem.forEach((item) => {
      item.classList.remove("checked");
    });
    inputs.forEach((input) => {
      if (input.checked) {
        input.checked = false;
      }
    });
    // Dispatch action to update SAVED_SEARCH_STATUS
    // dispatch({ type: "SAVED_SEARCH_STATUS", payload: false });
    setIsInputsChecked(false);
    resetCandidatesList();
  };
  const industriesCheckox = (checkboxId) => {
    // Get the checkbox element
    var checkbox = document.getElementById(checkboxId);

    // If the clicked checkbox is already checked, uncheck it
    if (!checkbox.checked) {
      checkbox.checked = false;
    } else {
      let checkboxes = document.querySelectorAll(
        '#collapse-10 input[type="checkbox"]'
      );

      checkboxes.forEach(function (cb) {
        if (cb.id !== checkboxId) {
          cb.checked = false;
        }
      });
      // Then check the clicked checkbox
      checkbox.checked = true;
    }
  };
  const resetAllParams = () => {
    SEARCH_PARAMS = "";
    urlParms = "";
    levelIDs = [];
    passportParams = [];
    resumeParams = [];
    docsParams = [];
    licenseParams = [];
    genderParams = [];
    industriesIDs = "";
    fieldsIDs = [];
    activitiesIDs = [];
    countriesIDs = [];
    nationalitiesIDs = [];
    languagesIDs = [];
    availabilityID = "";
    fromAge = "";
    toAge = "";
    fromHeight = "";
    toHeight = "";
    fieldParams = "";
    setFieldID(0);
  };

  // get field ID
  const getFieldID = (id) => {
    setFieldID(id);
  };

  // get activity IDs
  const getActivityIDs = (ids) => {
    console.log(ids);
    setActivityID(ids);
  };

  const changeHandler = (e, title, minRange, maxRange, type) => {
    let isChecked = false;
    if (e?.target.checked) {
      isChecked = true;
    }
    checkInputsChecker();
    console.log(urlParms);
    if (e) {
      if (e.target.type === "checkbox") {
        if (title === "industry") {
          if (!e.target.checked) {
            industriesIDs = "";
          } else {
            industriesIDs = `${e.target.value}&`;
          }
        } else if (title === "field") {
          pushOrRemoveFromArray(fieldsIDs, e.target.value);
        } else if (title === "activity") {
          pushOrRemoveFromArray(activitiesIDs, e.target.value);
        } else if (title === "country") {
          pushOrRemoveFromArray(countriesIDs, e.target.value);
        } else if (title === "nationality") {
          pushOrRemoveFromArray(nationalitiesIDs, e.target.value);
        } else if (title === "language") {
          pushOrRemoveFromArray(languagesIDs, e.target.value);
        } else if (e.target.title === "gender") {
          if (!e.target.checked) {
            genderParams = [];
          } else {
            genderParams = `gender=${e.target.value}&`;
          }
        } else if (e.target.title === "level") {
          pushOrRemoveFromArray(levelIDs, e.target.value);
        } else if (e.target.title === "passport") {
          pushOrRemoveFromArray(passportParams, e.target.value);
        } else if (e.target.title === "resume") {
          pushOrRemoveFromArray(resumeParams, e.target.value);
        } else if (e.target.title === "docs") {
          pushOrRemoveFromArray(docsParams, e.target.value);
        } else if (e.target.title === "license") {
          pushOrRemoveFromArray(licenseParams, e.target.value);
        }
      } else if (e.target.type === "radio") {
        // if (e.target.title === "gender") {
        // if (e.target.value !== "all") {
        //   genderParams = `gender=${e.target.value}&`;
        // }
        // else {
        //   genderParams = "";
        //   isChecked = false;
        // }
        // }
      } else if (e.target.type === "range") {
        if (title === "availability") {
          availabilityID = `availability[]=${e.target.value}`;
          console.log(availabilityID);
        }
      }

      if (isChecked || e.target.type === "range") {
        e.target.closest(".accordion-item").classList.add("checked");
      } else {
        e.target.closest(".accordion-item").classList.remove("checked");
      }
    } else {
      if (title === "age") {
        fromAge = `from_age=${minRange}&`;
        toAge = `to_age=${maxRange}&`;
      } else {
        fromHeight = `from_height=${minRange}&`;
        toHeight = `to_height=${maxRange}&`;
      }
    }

    // Dispatch action to update SAVED_SEARCH_STATUS
    dispatch({ type: "SAVED_SEARCH_STATUS", payload: true });

    // level params
    let levelParams = "";
    levelIDs.map((level) => (levelParams += level + "&"));
    fieldParams = "";
    // fields params
    fieldsIDs.map((field) => (fieldParams += field + "&"));
    activityParams = "";
    // activity params
    activitiesIDs.map((activity) => (activityParams += activity + "&"));

    console.log(activityParams);
    countryParams = "";
    // country params
    countriesIDs.map((country) => (countryParams += country + "&"));
    // nationality params
    nationalityParams = "";
    nationalitiesIDs.map(
      (nationality) => (nationalityParams += nationality + "&")
    );
    languageParams = "";
    // language params
    languagesIDs.map((language) => (languageParams += language + "&"));

    // dispatch all candidates "levelParams & availabilityID"
    urlParms = `${industriesIDs}${fieldParams}${activityParams}${countryParams}${nationalityParams}${languageParams}${genderParams}${fromAge}${toAge}${fromHeight}${toHeight}${passportParams}${resumeParams}${docsParams}${licenseParams}`;
    console.log("url", urlParms);
    const url = `${state.HTTP_URL}/candidate/all?${urlParms}list_id=${state.ACTIVE_LIST_ID}&user_id=${USER_ID}`;
    SEARCH_PARAMS = urlParms;
    console.log("SEARCH_PARAMS", SEARCH_PARAMS);
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          // Dispatch action to update CANDIDATES_LIST
          dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        // setLoading(false);
      }
    }

    fetchData();
  };

  const resetCandidatesList = () => {
    const url = `${state.HTTP_URL}/candidate/all?list_id=${state.ACTIVE_LIST_ID}&user_id=${USER_ID}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          // Dispatch action to update CANDIDATES_LIST
          dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
          dispatch({ type: "SAVED_SEARCH_STATUS", payload: false });
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        // setLoading(false);
      }
    }

    fetchData();
  };

  const addAllCandidatesToList = async () => {
    let candaditesIDS = [];
    candaditesList.map((candadite) => candaditesIDS.push(candadite.id));
    console.log(candaditesIDS);

    // const selectedEl = e.target.closest(".candidate-container");
    const addToListUrl = `${state.HTTP_URL}/add_to_list`;
    const listID = state.ACTIVE_LIST_ID;
    if (!state.ACTIVE_LIST_ID) {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
      dispatch({ type: "RECRUIT_LISTS_STATUS", payload: true });
    }
    // if (!selectedEl.classList.contains("selected")) {
    const responseData = await AddToListReq(
      addToListUrl,
      JSON.stringify(candaditesIDS),
      listID
      // selectedEl
    );
    console.log("Add", responseData);
    const url = `${state.HTTP_URL}/lists`;
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
        urlParms = `${industriesIDs}${fieldParams}${activityParams}${countryParams}${nationalityParams}${languageParams}${genderParams}${fromAge}${toAge}${fromHeight}${toHeight}${passportParams}${resumeParams}${docsParams}${licenseParams}`;
        const url = `${state.HTTP_URL}/candidate/all?${urlParms}list_id=${state.ACTIVE_LIST_ID}&user_id=${USER_ID}`;
        console.log("SEARCH_PARAMS", SEARCH_PARAMS);
        // Define an async function to fetch the data
        async function fetchData() {
          try {
            const response = await axios.get(url);
            if (response.status === 200) {
              // Dispatch action to update CANDIDATES_LIST
              dispatch({
                type: "CANDIDATES_LIST",
                payload: response.data.data,
              });
              // setLoading(false);
            }
          } catch (error) {
            console.error("Error:", error);
            // setLoading(false);
          }
        }

        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (isSaved) {
    return (
      <div
        className={`tags-container sidebar-container ${
          state.CANDIDATES_SIDE_NAME === "tags" && "active"
        }`}
      >
        <SetName
          afterSaved={clearInputsChecker}
          searchParms={SEARCH_PARAMS}
          onClose={cancelSetSaved}
        />
      </div>
    );
  }
  return (
    <div
      className={`tags-container sidebar-container ${
        state.CANDIDATES_SIDE_NAME === "tags" && "active"
      }`}
    >
      <div className="tags-tabs-container d-flex justify-content-end">
        <div
          className="tags-tab me-3 normal-text active pointer"
          data-type="qualifications"
          onClick={(e) => activeTagsTabHandler(e)}
        >
          QUALIFICATIONS
        </div>
        <div
          className="tags-tab normal-text pointer"
          data-type="personal"
          onClick={(e) => activeTagsTabHandler(e)}
        >
          PERSONAL
        </div>
      </div>

      <div
        className={`list-control tags-action-btns ${
          isInputsChecked ? "show" : ""
        }`}
      >
        <button
          className="reset-btn mb-4 normal-text"
          onClick={addAllCandidatesToList}
        >
          ADD ALL
        </button>
        <div className={`d-flex align-items-center justify-content-between`}>
          <button
            className="reset-btn submit-btn red-btn p-2 clear-tags normal-text"
            onClick={clearInputsChecker}
          >
            CLEAR ALL
          </button>
          <button
            className="reset-btn submit-btn p-2 normal-text"
            onClick={() => setIsSaved(true)}
          >
            SAVE SEARCH
          </button>
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <div className="accordion" id="tagsAccordion">
        <div className="tags-tabs-content-container">
          <div className="qualification-tab-content active">
            <ApiTagsDataFetcher
              apiUrl={`${state.HTTP_URL}/industries`}
              title="industry"
              inputType="checkbox"
              number="1"
              setFieldId={getFieldID}
              valueType="industries"
              onChangeHandler={changeHandler}
            />
            {fieldID > 0 && (
              <ApiTagsDataFetcher
                apiUrl={`${state.HTTP_URL}/fields/${fieldID}`}
                title="field"
                inputType="checkbox"
                number="2"
                setActivityId={getActivityIDs}
                valueType="fields"
                onChangeHandler={changeHandler}
              />
            )}
            {fieldParams && (
              <ApiTagsDataFetcher
                apiUrl={`${state.HTTP_URL}/activites?${decodeURIComponent(
                  new URLSearchParams(fieldParams).toString()
                ).replace(/fields\[\]=/g, "field_id[]=")}`}
                title="activity"
                inputType="checkbox"
                number="3"
                valueType="activities"
                onChangeHandler={changeHandler}
              />
            )}

            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-4">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-4"
                  aria-expanded="false"
                  aria-controls="collapse-4"
                >
                  <span>LEVEL</span>
                </button>
              </h2>
              <div
                id="collapse-4"
                className="accordion-collapse collapse"
                aria-labelledby="heading-4"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="wraped-words">
                    <span className="text-uppercase">
                      <input
                        id="graduate"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=1"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="graduate">
                        GRADUATE
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="employed"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=2"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="employed">
                        EMPLOYED
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="studying"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=3"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="studying">
                        STUDYING
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="experience"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=4"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="experience">
                        EXPERIENCE
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="unemployed"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=5"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="unemployed">
                        UNEMPLOYED
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="fresh"
                        type="checkbox"
                        name="level"
                        title="level"
                        value="level[]=6"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="fresh">
                        FRESH
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-5">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                  aria-controls="collapse-5"
                >
                  <span>DOCUMENTATION</span>
                </button>
              </h2>
              <div
                id="collapse-5"
                className="accordion-collapse collapse"
                aria-labelledby="heading-5"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="wraped-words">
                    <span className="text-uppercase">
                      <input
                        id="passport"
                        type="checkbox"
                        name="documentation"
                        title="passport"
                        value="passport=1&"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="passport">
                        PASSPORT
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="resume"
                        type="checkbox"
                        name="documentation"
                        title="resume"
                        value="resume=1&"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="resume">
                        RESUME
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="docs"
                        type="checkbox"
                        name="documentation"
                        title="docs"
                        value="docs=1&"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="docs">
                        DOCS
                      </label>
                    </span>
                    <span className="text-uppercase">
                      <input
                        id="license"
                        type="checkbox"
                        name="documentation"
                        title="license"
                        value="license=1&"
                        onChange={(e) => changeHandler(e)}
                      />
                      <label className="normal-text" htmlFor="license">
                        LICENSE
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-tab-content">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-6">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-6"
                  aria-expanded="false"
                  aria-controls="collapse-6"
                >
                  <span>AVAILABILITY</span>
                </button>
              </h2>
              <div
                id="collapse-6"
                className="accordion-collapse collapse"
                aria-labelledby="heading-6"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="personal-container d-flex align-items-center">
                    <div className="personal-title availability text-uppercase normal-text">
                      <b className="now active">NOW</b>
                      <b className="within">WITHIN</b>
                    </div>
                    <RangeBar
                      // title="WITHIN"
                      sliderOne="availability-slider-1"
                      rangeOne="availability-range-1"
                      sliderTrack="availability-slider-track"
                      percent="MONTH"
                      max="6"
                      title="availability"
                      onChangeHandler={changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            <ApiTagsDataFetcher
              apiUrl={`${state.HTTP_URL}/countries`}
              title="country"
              inputType="checkbox"
              number="7"
              valueType="countries"
              onChangeHandler={changeHandler}
            />

            <ApiTagsDataFetcher
              apiUrl={`${state.HTTP_URL}/nationalities`}
              title="nationality"
              inputType="checkbox"
              number="8"
              valueType="nationalities"
              onChangeHandler={changeHandler}
            />
            <ApiTagsDataFetcher
              apiUrl={`${state.HTTP_URL}/languages`}
              title="language"
              inputType="checkbox"
              number="9"
              valueType="languages"
              onChangeHandler={changeHandler}
            />

            <div className="accordion-item">
              <h2 className="accordion-header" id="heading-10">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-10"
                  aria-expanded="false"
                  aria-controls="collapse-10"
                >
                  <span>Gender</span>
                </button>
              </h2>
              <div
                id="collapse-10"
                className="accordion-collapse collapse"
                aria-labelledby="heading-10"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="personal-container mb-3">
                    <div className="wraped-words">
                      <span className="text-uppercase">
                        <input
                          id="male"
                          type="checkbox"
                          name="gender"
                          title="gender"
                          value="male"
                          onChange={(e) => changeHandler(e)}
                          onClick={() => industriesCheckox("male")}
                        />
                        <label className="normal-text" htmlFor="male">
                          MALE
                        </label>
                      </span>
                      <span className="text-uppercase">
                        <input
                          id="female"
                          type="checkbox"
                          name="gender"
                          title="gender"
                          value="female"
                          onChange={(e) => changeHandler(e)}
                          onClick={() => industriesCheckox("female")}
                        />
                        <label className="normal-text" htmlFor="female">
                          female
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item age-item">
              <h2 className="accordion-header" id="heading-11">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-11"
                  aria-expanded="false"
                  aria-controls="collapse-11"
                >
                  <span>Age</span>
                </button>
              </h2>
              <div
                id="collapse-11"
                className="accordion-collapse collapse"
                aria-labelledby="heading-11"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="personal-container mt-2 mb-2">
                    <MultiRange
                      title="age"
                      min="18"
                      max="45"
                      fromTitle="from_age"
                      toTitle="to_age"
                      onChangeHandler={changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item height-item">
              <h2 className="accordion-header" id="heading-12">
                <button
                  className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-12"
                  aria-expanded="false"
                  aria-controls="collapse-12"
                >
                  <span>Height</span>
                </button>
              </h2>
              <div
                id="collapse-12"
                className="accordion-collapse collapse"
                aria-labelledby="heading-12"
                data-bs-parent="#tagsAccordion"
              >
                <div className="accordion-body">
                  <div className="personal-container mt-2 mb-2">
                    <MultiRange
                      title="height"
                      percent="cm"
                      min="100"
                      max="200"
                      fromTitle="from_height"
                      toTitle="to_height"
                      onChangeHandler={changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tags;
