import React, { useState, useEffect } from "react";
import $ from "jquery";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./MultiRange.scss";

function MultiRange({
  title,
  min,
  max,
  fromTitle,
  toTitle,
  onChangeHandler,
  percent,
}) {
  const [values, setValues] = useState({
    min: parseInt(min, 10),
    max: parseInt(max, 10),
  });

  const handleChange = (newValues) => {
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, ...newValues };
      return updatedValues;
    });
    onChangeHandler(null, title, newValues.min, newValues.max, "multiRange");
    if (title === "age") {
      document
        .querySelector(".accordion-item.age-item")
        .classList.add("checked");
    }
    if (title === "height") {
      document
        .querySelector(".accordion-item.height-item")
        .classList.add("checked");
    }
  };
  useEffect(() => {
    if (percent === "cm") {
      var spanElement = $("<span>")
        .addClass("custom-percent")
        .text(`${percent}`);
      setTimeout(() => {
        $(`.height .input-range__slider-container .input-range__label`).append(
          spanElement
        );
      }, 1000);
    }
  }, []);
  return (
    <div className={`range-wrapper ${title}`}>
      <InputRange
        draggableTrack
        maxValue={parseInt(max, 10)}
        minValue={parseInt(min, 10)}
        value={values}
        onChange={handleChange}
      />
    </div>
  );
}

export default MultiRange;
