import React, { useState } from "react";
import CandidateModal from "../Modals/CandidateModal/CandidateModal";
import DeleteCandidateModal from "../Modals/DeleteCandidateModal/DeleteCandidateModal";
import Tags from "../CandidatesSides/Tags/Tags";
import SavedSearch from "../CandidatesSides/SavedSearch/SavedSearch";
import Lists from "../RecruitSides/Lists/Lists";
import Submitted from "../RecruitSides/Submitted/Submitted";
import { AddToListReq } from "../../HTTPS/AddToList/AddToListReq";
import { DeleteFromListReq } from "../../HTTPS/DeleteFromList/DeleteFromList";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import "./CandidatesGrid.scss";
let deletedCandaditeItem;
// const face = "https://i.postimg.cc/xTPXP2KP/face.png";
// const body = "https://i.postimg.cc/151FHBby/body.png";
function CandidatesGrid(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeCandaditeID, setActiveCandaditeID] = useState(0);
  const [candidateId, setCandidateId] = useState(null);
  // const [activeCandaditeID, setActiveCandaditeID] = useState(0);
  const [candaditeModal, setCandaditeModal] = useState(null);
  const [deleteCandidatePopup, setDeleteCandidatePopup] = useState(false);
  const { state, dispatch } = useAppContext();
  const candidatesList = state.CANDIDATES_LIST;
  const recruitCandidatesList = state.RECRUIT_CANDIDATES_LIST;
  const recruitSelectedList = state.SELECTED_LIST_ID;
  const listID = state.ACTIVE_LIST_ID;
  const [currentIndex, setCurrentIndex] = useState(1);
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const USER_ID = localStorage.getItem("USER_ID");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  // console.log("CANDIDATES_LIST", candidatesList);
  // add to list
  const toggleAddingToList = async (e) => {
    const selectedEl = e.target.closest(".candidate-container");
    const addToListUrl = `${state.HTTP_URL}/add_to_list`;
    const deleteFromListUrl = `${state.HTTP_URL}/delete_from_list`;
    if (!state.ACTIVE_LIST_ID) {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
      dispatch({ type: "RECRUIT_LISTS_STATUS", payload: true });
    }
    if (!selectedEl.classList.contains("selected")) {
      const responseData = await AddToListReq(
        addToListUrl,
        e.currentTarget.dataset.id,
        listID,
        selectedEl,
        updateRecruitLists
      );
      // console.log("Add", responseData);
    } else {
      const responseData = await DeleteFromListReq(
        deleteFromListUrl,
        e.currentTarget.dataset.id,
        listID,
        selectedEl,
        updateRecruitLists
      );
      // console.log("Delete", responseData);
    }
  };
  // toggle Favorite Candidate
  const toggleFavoriteCandidate = async (e, type) => {
    const el = e.currentTarget;
    const url = `${state.HTTP_URL}/favorite`;
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const data = {
      candidate_id: e.currentTarget.dataset.id,
    };
    try {
      const response = await axios.post(url, data, {
        headers: customHeaders,
      });
      if (response.status === 200) {
        console.log(response.data);
        const url = `${state.HTTP_URL}/favorites`;
        try {
          const response = await axios.get(url, {
            headers: customHeaders,
          });
          if (response.status === 200) {
            const candidatesArray = response.data.data.map(
              (item) => item.candidate
            );

            // Dispatch action to update RECRUIT_CANDIDATES_LIST with the new array
            dispatch({
              type: "RECRUIT_CANDIDATES_LIST",
              payload: candidatesArray,
            });
            dispatch({
              type: "SELECTED_LIST_NAME",
              payload: "FAVORITES",
            });
            console.log(candidatesArray);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (type === "add") {
      el.nextSibling.classList.add("active");
      el.classList.remove("active");
      console.log("add ID:", el.dataset.id);
    } else {
      el.previousSibling.classList.add("active");
      el.classList.remove("active");
      console.log("remove ID:", el.dataset.id);
    }
  };
  // toggle Favorite Candidate
  const toggleFetchCandidateStatus = async (e, type) => {
    // const el = e.currentTarget;
    const url = `${state.HTTP_URL}/${type}`;
    const candaditeId = e.currentTarget.dataset.id;
    const candaditeItem = e.currentTarget.closest(".reset-col");
    console.log(candaditeItem);
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const data = {
      list_id: state.SELECTED_LIST_ID,
      candidate_id: candaditeId,
    };
    try {
      const response = await axios.post(url, data, {
        headers: customHeaders,
      });
      if (response.status === 200) {
        if (type === "maybe") {
          dispatch({
            type: "MAYBE_LIST_LENGTH",
            payload: state.MAYBE_LIST_LENGTH + 1,
          });
          dispatch({
            type: "IDEAL_LIST_LENGTH",
            payload: state.IDEAL_LIST_LENGTH - 1,
          });
          candaditeItem.classList.add("maybe");
          candaditeItem.classList.add("hide");
          candaditeItem.classList.remove("ideal");
        } else {
          dispatch({
            type: "MAYBE_LIST_LENGTH",
            payload: state.MAYBE_LIST_LENGTH - 1,
          });
          dispatch({
            type: "IDEAL_LIST_LENGTH",
            payload: state.IDEAL_LIST_LENGTH + 1,
          });
          candaditeItem.classList.remove("maybe");
          candaditeItem.classList.add("ideal");
          candaditeItem.classList.add("hide");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // add to Maybe
  const addToMaybe = (e) => {
    e.currentTarget.previousSibling.classList.add("active");
    e.currentTarget.classList.remove("active");
    toggleFetchCandidateStatus(e, "maybe");
  };
  // add to Ideal
  const addToIdeal = (e) => {
    e.currentTarget.nextSibling.classList.add("active");
    e.currentTarget.classList.remove("active");
    toggleFetchCandidateStatus(e, "ideal");
  };

  // get candidate ID and open the modal
  const openCandidateModal = (id, listId) => {
    console.log(candidatesList);
    for (let x = 0; x < candidatesList.length; x++) {
      const element = candidatesList[x];
      if (id === element.id) {
        console.log(element.id);
      }
    }
    setActiveCandaditeID(id);
    // setLoading(true);
    console.log("open Modal ID:", id);
    console.log("activeCandaditeID:", activeCandaditeID);
    let url = `${state.HTTP_URL}/candidate/show/${id}?user_id=${USER_ID}`;
    if (listId) {
      url = `${state.HTTP_URL}/candidate/show/${id}?user_id=${USER_ID}&list_id=${listId}`;
    }
    console.log(url);
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          setCandaditeModal(response.data.data);
          // setLoading(false);
          // console.log(response.data.data);
        }
      } catch (error) {
        console.error("Error:", error);
        // setLoading(false);
      }
    }

    fetchData();
    setIsOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  // open delete candidate modal
  const openDeleteCandidateModal = (e) => {
    // console.log("open Modal ID:", e.currentTarget.dataset.id);
    setDeleteCandidatePopup(true);
    document.body.classList.add("overflow-hidden");
    setCandidateId(e.currentTarget.dataset.id);
    deletedCandaditeItem = e.currentTarget.closest(".reset-col");
  };

  const countIDealLength = (array) => {
    const filteredArray = array.filter(
      (obj) => obj.business_status === "ideal"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const countIMaybeLength = (array) => {
    const filteredArray = array.filter(
      (obj) =>
        obj.business_status === "maybe" || obj.business_status === "pending"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  // Post Request
  const removeFromList = () => {
    const url = `${state.HTTP_URL}/delete_from_list`;
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const data = {
      list_id: state.SELECTED_LIST_ID,
      candidate_id: candidateId,
    };
    console.log(data);
    axios
      .post(url, data, {
        headers: customHeaders,
      })
      .then((response) => {
        console.log(response.data);
        document.body.classList.remove("overflow-hidden");
        // deletedCandaditeItem.parentNode.removeChild(deletedCandaditeItem);
        updateRecruitLists();
        setDeleteCandidatePopup(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    console.log(state.SELECTED_LIST_ID);
    const url2 = `${state.HTTP_URL}/list_candicates/${state.SELECTED_LIST_ID}`;
    setTimeout(() => {
      axios
        .get(url2, {
          headers: customHeaders,
        })
        .then((response) => {
          console.log("after Delete", response.data);
          let candidatesInList = [];

          response.data.data.map((item) =>
            candidatesInList.push(item.candidate)
          );

          const countIMaybe = countIMaybeLength(candidatesInList);
          const countIDeal = countIDealLength(candidatesInList);
          dispatch({
            type: "RECRUIT_CANDIDATES_LIST",
            payload: candidatesInList,
          });
          dispatch({
            type: "ALL_LIST_LENGTH",
            payload: candidatesInList.length,
          });
          dispatch({ type: "MAYBE_LIST_LENGTH", payload: countIMaybe });
          dispatch({ type: "IDEAL_LIST_LENGTH", payload: countIDeal });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 1000);
  };

  const updateRecruitLists = () => {
    const url = `${state.HTTP_URL}/lists`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // candidates items
  const candidatesItems = candidatesList.map((candidate) => (
    <div className={`reset-col ${props.columns}`} key={candidate.id}>
      <div
        className={`candidate-container ${
          candidate.list_selected ? "selected" : ""
        }`}
      >
        <div
          className="overlay"
          data-id={candidate.id}
          onClick={() => openCandidateModal(candidate.id, state.ACTIVE_LIST_ID)}
        ></div>
        <img
          className="candidate-img"
          src={candidate.photo1}
          data-face={candidate.photo1}
          data-body={candidate.photo2}
          alt="candidate name"
          onClick={() => openCandidateModal(candidate.id, state.ACTIVE_LIST_ID)}
          data-id={candidate.id}
        />
        <div className={`d-flex justify-content-between candidate-control`}>
          <i
            className="icon-plus-circle icon addToList-btn"
            onClick={toggleAddingToList}
            data-id={candidate.id}
          ></i>
          <i
            className="icon-minus-circle icon removeToList-btn"
            onClick={toggleAddingToList}
            data-id={candidate.id}
          ></i>

          <div className="favorites-icons">
            <i
              className={`icon-heart-blank icon addToFavorites ${
                candidate.favorite ? "" : "active"
              }`}
              onClick={(e) => toggleFavoriteCandidate(e, "add")}
              data-id={candidate.id}
            ></i>
            <i
              className={`icon-heart-fill icon removeFromFavorites ${
                candidate.favorite ? "active" : ""
              }`}
              onClick={(e) => toggleFavoriteCandidate(e, "remove")}
              data-id={candidate.id}
            ></i>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center user-info">
          <div className="normal-text">
            <span className="firstName text-uppercase">
              <b>{candidate.first_name}</b>
            </span>
            <span>, </span>
            <span className="age">{candidate.age}</span>
          </div>
          <div>
            <span className="country text-uppercase normal-text">
              {candidate.country_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  // recruit candidatesItems items
  const recruitCandidatesItems = recruitCandidatesList.map((candidate) => (
    <div
      className={`reset-col candidate-item ${
        candidate.business_status === "pending" ||
        candidate.business_status === "maybe"
          ? "maybe"
          : "ideal"
      } ${props.columns}`}
      key={candidate.id}
    >
      <div className={`candidate-container ${state.TAB_LIST_NAME}`}>
        <div
          className="overlay"
          data-id={candidate.id}
          onClick={
            state.SELECTED_LIST_NAME !== "FAVORITES"
              ? () => openCandidateModal(candidate.id, state.ACTIVE_LIST_ID)
              : null
          }
        ></div>
        <img
          className="candidate-img"
          src={candidate.photo1}
          data-face={candidate.photo1}
          data-body={candidate.photo2}
          alt="candidate name"
          onClick={
            state.SELECTED_LIST_NAME !== "FAVORITES"
              ? () => openCandidateModal(candidate.id, state.ACTIVE_LIST_ID)
              : null
          }
          data-id={candidate.id}
        />
        {state.RECRUIT_SIDE_NAME !== "submitted" && (
          <div
            className={`d-flex justify-content-between candidate-control show-controls`}
          >
            <div
              className={`favorites-icons maybeIdeal-icons ${
                candidate.business_status === "pending" ||
                candidate.business_status === "maybe"
                  ? "maybe"
                  : "ideal"
              }`}
              data-status={candidate.business_status}
            >
              <i
                className={`icon-clock icon addToMaybe ${
                  candidate.business_status === "pending" ||
                  candidate.business_status === "maybe"
                    ? "active"
                    : null
                }`}
                onClick={addToIdeal}
                data-id={candidate.id}
              ></i>
              <i
                className={`icon-checkmark icon addToIdeal ${
                  candidate.business_status === "ideal" && "active"
                }`}
                onClick={addToMaybe}
                data-id={candidate.id}
              ></i>
            </div>
            {state.SELECTED_LIST_NAME !== "FAVORITES" && (
              <i
                className={`icon-clear-btn icon clearFromList ${
                  state.TAB_LIST_NAME === "pending-list" ? "show" : "hide"
                }`}
                onClick={openDeleteCandidateModal}
                data-id={candidate.id}
              ></i>
            )}

            <div className="favorites-icons">
              <i
                className={`icon-heart-blank icon addToFavorites ${
                  candidate.favorite ? "" : "active"
                }`}
                onClick={(e) => toggleFavoriteCandidate(e, "add")}
                data-id={candidate.id}
              ></i>
              <i
                className={`icon-heart-fill icon removeFromFavorites ${
                  candidate.favorite ? "active" : ""
                }`}
                onClick={(e) => toggleFavoriteCandidate(e, "remove")}
                data-id={candidate.id}
              ></i>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center user-info">
          <div className="normal-text">
            <span className="firstName text-uppercase">
              <b>{candidate.first_name}</b>
            </span>
            <span>, </span>
            <span className="age">{candidate.age}</span>
          </div>
          <div>
            <span className="country text-uppercase normal-text">
              {candidate.country_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container-fluid">
      <div className="main-two-sides-container">
        <div
          className={`candidates-side m-0 ps-0 ${props.imgPosClass} ${
            state.CANDIDATES_CONTAINER_STATUS ? "col-12" : "col-12 moveToRight"
          }`}
        >
          <div className="row">
            {/* {candaditeModal && ( */}
            <CandidateModal
              open={isOpen}
              onClose={() => setIsOpen(false)}
              info="test"
              type={props.type}
              candaditeModalData={candaditeModal}
              onSwitchCandidate={openCandidateModal}
            ></CandidateModal>
            {/* // )} */}
            <DeleteCandidateModal
              open={deleteCandidatePopup}
              onClose={() => setDeleteCandidatePopup(false)}
              onRemoveFromList={removeFromList}
            ></DeleteCandidateModal>
            {props.type === "candidates" ? (
              candidatesList.length ? (
                candidatesItems
              ) : (
                <h2 className="mid-bold-text text-center">No Results Found!</h2>
              )
            ) : recruitCandidatesList.length > 0 ? (
              recruitCandidatesItems
            ) : recruitCandidatesList.length === 0 && recruitSelectedList ? (
              <>
                <h2 className="mid-bold-text text-center">List is empty</h2>
              </>
            ) : (
              <h2 className="mid-bold-text text-center">
                Please Select a List
              </h2>
            )}
            {/* <div className={`side-bar ${state.SIDE_STATUS ? "animated" : ""}`}> */}
            {props.type === "candidates" ? (
              <div
                className={`side-bar ${
                  state.CANDIDATES_SIDE_STATUS ? "animated" : ""
                }`}
              >
                <Tags />
                <SavedSearch title={props.title} />
              </div>
            ) : (
              <div
                className={`side-bar ${
                  state.RECRUIT_SIDE_STATUS ? "animated" : ""
                }`}
              >
                <Lists />
                <Submitted />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidatesGrid;
