import React from "react";
import ReactDOM from "react-dom";
import "./DeleteCandidateModal.scss";

function DeleteCandidateModal({ open, children, onClose, onRemoveFromList }) {
  const closeModal = () => {
    document.body.classList.remove("overflow-hidden");
    onClose();
  };
  //   if (!open) return null;
  return ReactDOM.createPortal(
    <div className={`delete-candidate-modal ${open ? "is-open" : ""}`}>
      <div className="modal-content-container m-0">
        <span className="close-wrapper">
          <i className="icon-close"></i>
        </span>
        <h4 className="bold-text delete-title text-uppercase mt-4">
          Are you sure?
        </h4>
        <h5 className="sm-bold-text mt-5 text-msg">
          Do you really want to delete <span>Component Name</span>
        </h5>
        <div className="action-btns d-flex justify-content-evenly align-items-center profile-control mt-5">
          <input
            className="reset-btn cancel-btn submit-btn normal-text text-uppercase"
            type="button"
            value="CANCEL"
            onClick={closeModal}
          />
          <input
            className="reset-btn delete-btn submit-btn normal-text text-uppercase"
            type="submit"
            value="Delete"
            onClick={onRemoveFromList}
          />
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default DeleteCandidateModal;
