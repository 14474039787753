import React, { useState } from "react";
import { useAppContext } from "../../../AppContext";
import axios from "axios";
function SetName(props) {
  const [isValid, setIsValid] = useState(false);
  const { state, dispatch } = useAppContext();
  const searchesUrl = `${state.HTTP_URL}/searches`;
  const saveSearchUrl = `${state.HTTP_URL}/save_search`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };

  const saveSearchHandler = async (title, link) => {
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    if (props.edit) {
      const editSearchUrl = `${state.HTTP_URL}/edit_search/${props.listId}`;
      console.log(editSearchUrl);
      const data = {
        title,
      };
      // Save the new search
      try {
        const response = await axios.post(editSearchUrl, data, {
          headers: customHeaders,
        });
        updateSavedSearch();
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      const data = {
        link,
        title,
      };
      // Save the new search
      try {
        const response = await axios.post(saveSearchUrl, data, {
          headers: customHeaders,
        });
        setIsValid(false);
        dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
        dispatch({ type: "SAVED_SEARCH_NAME", payload: title });
        dispatch({ type: "SIDE_STATUS", payload: true });
        // dispatch({ type: "SIDE_NAME", payload: "saved" });
        console.log("state.ACTIVE_LIST_ID", state.ACTIVE_LIST_ID);
        console.log("state.SAVED_SEARCH_STATUS", state.SAVED_SEARCH_STATUS);
        console.log("state.ACTIVE_LIST_NAME", state.ACTIVE_LIST_NAME);
        console.log("state.SAVED_SEARCH_NAME", title);
        // props.afterSaved();
        props.onClose();
        // console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    // then update the SAVED_SEARCH_LIST
  };
  const saveName = async () => {
    let value = document.querySelector(".set-name").value;
    let link = `${state.HTTP_URL}/candidate/all?${props.searchParms}`;

    if (value.trim() !== "") {
      // search name is valid
      saveSearchHandler(value, link);
      props.onClose();
    } else {
      // show msg
      setIsValid(true);
      // return;
    }
  };
  const updateSavedSearch = async () => {
    try {
      const response = await axios.post(
        searchesUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      // Dispatch action to update SAVED_SEARCH_LIST
      console.log(response.data.data.SavedSearches);
      dispatch({
        type: "SAVED_SEARCH_LIST",
        payload: response.data.data.SavedSearches,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="set-name-container">
      <input
        className="set-name input-reset bordered-input"
        defaultValue={props.name ? props.name : ""}
        type="text"
        placeholder="Please add a Search Name"
      />
      <span className={`red-btn mid-text mt-3  ${isValid ? "show" : "hide"}`}>
        You have to add a Search Name first.
      </span>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="reset-btn submit-btn red-btn p-2"
          onClick={props.onClose}
        >
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn p-2 text-uppercase"
          onClick={saveName}
        >
          SAVE SEARCH NAME
        </button>
      </div>
    </div>
  );
}

export default SetName;
