import React, { Fragment } from "react";
import "./Documents.scss";

function Documents({ title, data }) {
  let documentsList;
  if (data) {
    console.log(data);
    documentsList = data.map((document, index) => (
      <Fragment key={index}>
        <span className="text-uppercase normal-text">{document.name}</span>
      </Fragment>
    ));
  }
  return (
    <>
      <p className="m-0 text-uppercase mb-3 section-title">
        <b>{title}</b>
      </p>
      {data ? (
        <div className="documnets-container wraped-words">{documentsList}</div>
      ) : (
        <p>No Documents added yet!</p>
      )}
    </>
  );
}

{
  /* <div className="wraped-words">
        <span className="text-uppercase normal-text">ID/PASSPORT</span>
        <span className="text-uppercase normal-text">CERTIFICATES</span>
        <span className="text-uppercase normal-text">RESUME</span>
        <span className="text-uppercase normal-text">MEDICAL</span>
      </div>
    </div> */
}

export default Documents;
