import Navbar from "../Navbar/Navbar";
import Lobby from "../Lobby/Lobby";
import CandidateProfile from "../Candidate/CandidateProfile/CandidateProfile";
import PageNotFound from "../Shared/PageNotFound/PageNotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import "./App.scss";

function Candidate() {
  return (
    <>
      <Router>
        <Navbar type="candidate" />
        <Routes>
          {/* <Route exact path="/" element={<Lobby />} /> */}
          <Route
            exact
            // path="/profile"
            path="/"
            element={<CandidateProfile type="candidate" />}
          />
          {/* <Route exact path="/guides" element={<p>Guides Page</p>} /> */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default Candidate;
