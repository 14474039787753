import React, { useState, useEffect } from "react";
import CandidateControlBar from "../../Shared/CandidateControlBar/CandidateControlBar";
import CandidateProfileData from "../CandidateProfileData/CandidateProfileData";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import Loader from "../../Shared/Loader/Loader";
// import {GetCandidateProfileDataObj} from "../../HTTPS/GetCandidateProfileDataObj/GetCandidateProfileDataObj"
import "./CandidateProfile.scss";

function CandidateProfile({ type }) {
  const { state, dispatch } = useAppContext();
  const [loader, setLoader] = useState(true);
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  useEffect(() => {
    // Post Request
    const url = `${state.HTTP_URL}/candidate_profile`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        dispatch({ type: "CANDIDATE_PROFILE", payload: response.data.data });
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <div className="candidate-profile">
      {loader ? (
        <Loader />
      ) : (
        <>
          <CandidateControlBar />
          <CandidateProfileData
            type={type}
            CandidateDataObj={state.CANDIDATE_PROFILE}
          />
        </>
      )}
    </div>
  );
}

export default CandidateProfile;
