import React from "react";
import { imageChangeHandler } from "../../../helpers/helpers";
function FilterActions({ onTogglePopOver, onSubmitPopover, onChangeHandler }) {
  return (
    <div className="filters-actions d-flex align-items-center justify-content-center">
      <div className="popover">
        <i
          className="icon-sort icon popover__trigger"
          onClick={(e) => onTogglePopOver(e)}
        ></i>
        <ul className="popover__menu">
          <li
            className="popover__menu-item normal-text d-flex justify-content-between"
            data-option="created_at"
            onClick={onSubmitPopover}
          >
            <span>RECENTLY ADDED</span>
            <span>
              <i className="icon-arrow-down-solid active"></i>
              <i className="icon-arrow-up-solid"></i>
            </span>
          </li>
          <li
            className="popover__menu-item normal-text d-flex justify-content-between"
            data-option="age"
            onClick={onSubmitPopover}
          >
            <span>AGE</span>
            <span>
              <i className="icon-arrow-down-solid active"></i>
              <i className="icon-arrow-up-solid"></i>
            </span>
          </li>
          <li
            className="popover__menu-item normal-text d-flex justify-content-between"
            data-option="height"
            onClick={onSubmitPopover}
          >
            <span>HEIGHT</span>
            <span>
              <i className="icon-arrow-down-solid active"></i>
              <i className="icon-arrow-up-solid"></i>
            </span>
          </li>
        </ul>
      </div>
      <div className="mx-3 body-filter">
        <span
          className="face normal-text"
          onClick={(e) => imageChangeHandler("face", e)}
        >
          HEADSHOT
        </span>
        <span
          className="body active normal-text"
          onClick={(e) => imageChangeHandler("body", e)}
        >
          FULL BODY
        </span>
      </div>
      <input
        type="range"
        id="set-columns"
        defaultValue="4"
        name="set-columns"
        min="2"
        max="4"
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default FilterActions;
