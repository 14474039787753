import React, { useState } from "react";
import DateFormat from "../../DateFormat/DateFormat";
import "./WorkExperience.scss";

function WorkEducation({ title, data }) {
  const [showMore, setShowMore] = useState(false);
  const displayCount = showMore ? data.length : 2;

  let workExperienceList;
  if (data) {
    workExperienceList = data.map((work, index) => {
      if (index < displayCount) {
        return (
          <div className="item-container mt-4" key={work.id}>
            <div className="d-flex justify-content-between align-items-center profile-control">
              <p className="m-0 text-uppercase">
                <b>{work.title}</b>
              </p>
              <p className="m-0 text-uppercase">
                {work.currently_work > 0 ? (
                  "Present"
                ) : (
                  <DateFormat date={work.to} />
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center profile-control">
              <p className="m-0">
                {work.company}, {work.country_name}, {work.city_name}
              </p>
              <p className="m-0 text-uppercase">
                {<DateFormat date={work.from} />}
              </p>
            </div>
          </div>
        );
      }
    });
  }

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <div className="work-education-container">
        <div className="d-flex justify-content-between align-items-center profile-control mb-4">
          <p className="m-0 text-uppercase section-title">
            <b>{title}</b>
          </p>
          {data.length > 2 && (
            <p
              className="m-0 text-uppercase pointer"
              onClick={handleToggleShowMore}
            >
              {showMore ? "LESS" : "MORE"}
            </p>
          )}
        </div>
        {data.length ? workExperienceList : <p>No data added yet!</p>}
      </div>
      <hr />
    </>
  );
}

export default WorkEducation;
