import React, { useState } from "react";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
import "./EditSocials.scss";
const EditSocials = ({ oncloseModal }) => {
  const { state, dispatch } = useAppContext();

  const [linkedin, setLinkedin] = useState(
    state.CANDIDATE_PROFILE.linkedin || ""
  );
  const [instagram, setInstagram] = useState(
    state.CANDIDATE_PROFILE.insta || ""
  );
  const [facebook, setFacebook] = useState(
    state.CANDIDATE_PROFILE.facebook || ""
  );
  const [theX, setTheX] = useState(state.CANDIDATE_PROFILE.twitter || "");
  const [linkedinError, setLinkedinError] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [theXError, setTheXError] = useState("");

  const validateURL = (url, platform) => {
    // Regular expressions for validating URLs of different social media platforms
    const urlRegex = {
      linkedin: /^https?:\/\/(www\.)?linkedin\.com\/in\/[\w-]+\/?$/,
      instagram: /^https?:\/\/(www\.)?instagram\.com\/[\w-]+\/?$/,
      facebook: /^https?:\/\/(www\.)?facebook\.com\/[\w.-]+\/?$/,
      theX: /^https?:\/\/(www\.)?example\.com\/[\w-]+\/?$/, // Change 'example.com' to your domain
    };
    return urlRegex[platform].test(url);
  };

  const linkedinHandler = (e) => {
    setLinkedin(e.target.value);
    if (
      e.target.value.trim() !== "" &&
      !validateURL(e.target.value, "linkedin")
    ) {
      setLinkedinError("Invalid LinkedIn URL");
    } else {
      setLinkedinError("");
    }
  };
  const instagramHandler = (e) => {
    setInstagram(e.target.value);
    if (
      e.target.value.trim() !== "" &&
      !validateURL(e.target.value, "instagram")
    ) {
      setInstagramError("Invalid Instagram URL");
    } else {
      setInstagramError("");
    }
  };
  const facebookHandler = (e) => {
    setFacebook(e.target.value);
    if (
      e.target.value.trim() !== "" &&
      !validateURL(e.target.value, "facebook")
    ) {
      setFacebookError("Invalid Facebook URL");
    } else {
      setFacebookError("");
    }
  };
  const theXHandler = (e) => {
    setTheX(e.target.value);
    if (e.target.value.trim() !== "" && !validateURL(e.target.value, "theX")) {
      setTheXError("Invalid X URL");
    } else {
      setTheXError("");
    }
  };
  const saveSocialsHandler = (e) => {
    const socialsData = {
      linkedin,
      insta: instagram,
      facebook,
      twitter: theX,
    };
    // post request
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const url = `${state.HTTP_URL}/candidate_update_profile_social`;
    axios
      .post(url, socialsData, {
        headers: customHeaders,
      })
      .then((response) => {
        console.log(response.data.data);
        oncloseModal();
        const url = `${state.HTTP_URL}/candidate_profile`;
        axios
          .post(
            url,
            {},
            {
              headers: customHeaders,
            }
          )
          .then((response) => {
            dispatch({
              type: "CANDIDATE_PROFILE",
              payload: response.data.data,
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <div className="edit-socials">
        <div className="social-item linkedin mt-4 d-flex align-items-center flex-wrap">
          <i className="icon-linkedin"></i>
          <div className="input-wrapper">
            <input
              className="text-uppercase"
              type="text"
              name="linkedin"
              placeholder="LINKEDIN"
              value={linkedin}
              onChange={linkedinHandler}
            />
          </div>
          {linkedinError && (
            <span className="validation-message">{linkedinError}</span>
          )}
        </div>
        <div className="social-item instagram mt-4 d-flex align-items-center flex-wrap">
          <i className="icon-instagram"></i>
          <div className="input-wrapper">
            <input
              className="text-uppercase"
              type="text"
              name="instagram"
              placeholder="INSTAGRAM"
              value={instagram}
              onChange={instagramHandler}
            />
          </div>
          {instagramError && (
            <span className="validation-message col">{instagramError}</span>
          )}
        </div>
        <div className="social-item facebook mt-4 d-flex align-items-center flex-wrap">
          <i className="icon-facebook"></i>
          <div className="input-wrapper">
            <input
              className="text-uppercase"
              type="text"
              name="facebook"
              placeholder="FACEBOOK"
              value={facebook}
              onChange={facebookHandler}
            />
          </div>
          {facebookError && (
            <span className="validation-message">{facebookError}</span>
          )}
        </div>
        <div className="social-item x mt-4 d-flex align-items-center flex-wrap">
          <i className="icon-x"></i>
          <div className="input-wrapper">
            <input
              className="text-uppercase"
              type="text"
              name="x"
              placeholder="X"
              value={theX}
              onChange={theXHandler}
            />
          </div>
          {theXError && <span className="validation-message">{theXError}</span>}
        </div>
      </div>
      <div className="d-flex justify-content-between edit-profile-action-btns edit-socials">
        <button className="reset-btn normal-text" onClick={oncloseModal}>
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn normal-text md-font"
          onClick={saveSocialsHandler}
        >
          SAVE
        </button>
      </div>
    </>
  );
};

export default EditSocials;
