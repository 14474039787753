import React, { useState } from "react";
import "./CandidateControlBar.scss";
import { getSiblings } from "../../../helpers/helpers";
import EditProfile from "../Modals/EditProfile/EditProfile";
function CandidateControlBar() {
  const [editTab, setEditTab] = useState("contact");
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const startEditHandler = () => {
    showEditTabs();
    openEditProfileModal(true);
  };
  const openEditProfileModal = (e) => {
    setIsEditProfileOpen(true);
  };
  const tabTypeHandler = (type) => {
    setEditTab(type);
  };
  const showEditTabs = () => {
    const editProfileTabs = document.querySelector(".edit-profile-tabs");
    editProfileTabs.classList.add("in");
  };
  const toggleActiveClass = (e) => {
    const el = e.currentTarget;
    let siblings = getSiblings(el);
    el.classList.add("active");
    siblings.map((e) => e.classList.remove("active"));
  };
  return (
    <>
      <EditProfile
        type={editTab}
        open={isEditProfileOpen}
        onClose={() => setIsEditProfileOpen(false)}
        onTabTypeHandler={tabTypeHandler}
        onToggleActiveClass={toggleActiveClass}
      />
      <div className="control-bar-container candidate-bar px-3">
        <div className="p-0 position-relative text-center d-flex">
          <div
            className="edit-profile pointer text-light normal-text col-6 text-end"
            onClick={startEditHandler}
          >
            <i className="icon-pencil me-2"></i>
            <span>EDIT PROFILE</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateControlBar;
