import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SubmittedGrid from "../../SubmittedGrid/SubmittedGrid";
import { useAppContext } from "../../../../AppContext";
import axios from "axios";
import "./Submitted.scss";

function Submitted() {
  const [withdrawListConfirm, setWithdrawListConfirm] = useState(false);
  const [withdrawListThanks, setWithdrawListThanks] = useState(false);
  const [controlList, setControlList] = useState(false);
  const [withdrawControlbtn, setWithdrawControlbtn] = useState(false);
  const [submittedListCandidatesCount, setSubmittedListCandidatesCount] =
    useState(0);
  const [submittedListAt, setSubmittedListAt] = useState("");
  const [withdrawListName, setWithdrawListName] = useState("");
  const [lists, setLists] = useState([]);
  const [activeList, setActiveList] = useState(0);

  const { state, dispatch } = useAppContext();
  const withdrawListUrl = `${state.HTTP_URL}/withdraw_list/${activeList}`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  let navigate = useNavigate();
  const navigateToConcierge = () => {
    navigate("/concierge");
  };
  // reset list
  const resetList = () => {
    setWithdrawListConfirm(false);
    setWithdrawListThanks(false);
  };
  // open withdraw confirm
  const withdrawConfirm = () => {
    resetList();
    setWithdrawListConfirm(true);
  };

  // open withdraw thanks
  const withdrawThanks = () => {
    withdrawListHandler();
  };
  const getSelectedSubmittedList = (id) => {
    setActiveList(id);
  };
  // open concierge
  const goToConcierge = () => {
    resetList();
    navigateToConcierge();
  };
  const withdrawListHandler = async () => {
    try {
      const response = await axios.post(
        withdrawListUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      if (response.status === 200) {
        resetList();
        setWithdrawListThanks(true);
        updateRecruitLists();
        updateSubmittedLists();
        // console.log(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const updateRecruitLists = () => {
    const url = `${state.HTTP_URL}/lists`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateSubmittedLists = () => {
    const url = `${state.HTTP_URL}/lists_submitted`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        dispatch({ type: "SUBMITTED_LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getListInfo = (id, listName) => {
    setWithdrawListName(listName);
    const url = `${state.HTTP_URL}/list_info/${id}`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setSubmittedListCandidatesCount(response.data.data.candidates_count);

        // Parse the input date string
        const dateObject = new Date(response.data.data.submitted_at);

        // Format the date as "DD MMM YYYY"
        const options = { day: "2-digit", month: "short", year: "numeric" };
        const formattedDate = dateObject
          .toLocaleDateString("en-US", options)
          .toUpperCase();
        setSubmittedListAt(formattedDate);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const url = `${state.HTTP_URL}/lists_submitted`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // setLists(response.data.data);
        dispatch({ type: "SUBMITTED_LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  let listsItems;
  if (state.SUBMITTED_LISTS_LIST.length) {
    listsItems = state.SUBMITTED_LISTS_LIST.map((item, i) => (
      <SubmittedGrid
        key={item.id}
        title={item.name}
        status={item.status}
        id={item.id}
        count={item.candidates_count}
        onGetSelectedSubmittedList={getSelectedSubmittedList}
        onSelectList={() => {
          setControlList(true);
          setWithdrawControlbtn(true);
        }}
        onSelectListHide={() => setWithdrawControlbtn(false)}
        onGetListInfo={getListInfo}
      />
    ));
  }
  if (withdrawListConfirm) {
    return (
      <div
        className={`submitted-container sidebar-container ${
          state.RECRUIT_SIDE_NAME === "submitted" && "active"
        }`}
      >
        <h4 className="bold-text">WANT TO WITHDRAW?</h4>
        <h5 className="mt-3 mb-3 sm-bold-text">{withdrawListName}</h5>
        <p>
          This action will return your selection to Lists section and our team
          will stop processing your request.
        </p>
        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn normal-text"
            type="button"
            value="CANCEL"
            onClick={resetList}
          />
          <input
            className="reset-btn submit-btn red-btn normal-text"
            type="button"
            value="WITHDRAW"
            onClick={withdrawThanks}
          />
        </div>
      </div>
    );
  } else if (withdrawListThanks) {
    return (
      <div
        className={`submitted-container sidebar-container ${
          state.RECRUIT_SIDE_NAME === "submitted" && "active"
        }`}
      >
        <h4 className="bold-text">WITHDRAWN!</h4>
        <p className="mt-3 mb-3">
          You can resubmit anytime from your Lists menu.
        </p>
        <p className="mt-3 mb-3">
          or click on concierge below and we will take care of your task for
          you.
        </p>
        <p className="mt-3 mb-3"> Good luck with your next search!</p>
        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn normal-text"
            type="button"
            value="CLOSE"
            onClick={resetList}
          />
          <input
            className="reset-btn submit-btn normal-text"
            type="button"
            value="CONCIERGE"
            onClick={goToConcierge}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`submitted-container sidebar-container ${
        state.RECRUIT_SIDE_NAME === "submitted" && "active"
      }`}
    >
      <div className={`list-control ${controlList ? "visible" : "hidden"}`}>
        <p className="mb-4">
          SUBMITTED {submittedListCandidatesCount} CANDIDATES ON{" "}
          {submittedListAt}
        </p>
        <div className="d-flex align-items-center justify-content-between">
          <button
            className={`reset-btn submit-btn red-btn p-2 normal-text ${
              !withdrawControlbtn && "hidden"
            }`}
            onClick={withdrawConfirm}
          >
            WITHDRAW
          </button>
        </div>
      </div>
      <hr className="mt-4 mb-4" />

      {listsItems}
    </div>
  );
}

export default Submitted;
