import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DeleteCandidateModal from "../DeleteCandidateModal/DeleteCandidateModal";
import Profile from "../../Profile/Profile";
import ShowCandidate from "../../../HTTPS/ShowCandidate/ShowCandidate";
import { useAppContext } from "../../../../AppContext";
import { AddToListReq } from "../../../HTTPS/AddToList/AddToListReq";
import { DeleteFromListReq } from "../../../HTTPS/DeleteFromList/DeleteFromList";
import axios from "axios";
import "./CandidateModal.scss";

function CandidateModal({
  open,
  onClose,
  type,
  candaditeModalData,
  onSwitchCandidate,
}) {
  const { state, dispatch } = useAppContext();
  const listID = state.ACTIVE_LIST_ID;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  const [deleteCandidatePopup, setDeleteCandidatePopup] = useState(false);

  // add to list
  const toggleAddingToList = async (e) => {
    const selectedEl = document.querySelector(".candidate-image-wrapper");
    const addToListUrl = `${state.HTTP_URL}/add_to_list`;
    const deleteFromListUrl = `${state.HTTP_URL}/delete_from_list`;
    if (!state.ACTIVE_LIST_ID) {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
      dispatch({ type: "OVERLAY_RECRUIT_LISTS_STATUS", payload: true });
      dispatch({ type: "RECRUIT_LISTS_STATUS", payload: true });
    }
    if (!selectedEl.classList.contains("selected")) {
      const responseData = await AddToListReq(
        addToListUrl,
        candaditeModalData?.id,
        listID,
        selectedEl,
        updateRecruitLists
      );
      // console.log("Add", responseData);
    } else {
      const responseData = await DeleteFromListReq(
        deleteFromListUrl,
        candaditeModalData?.id,
        listID,
        selectedEl,
        updateRecruitLists
      );
      // console.log("Delete", responseData);
    }
  };
  const updateRecruitLists = () => {
    const url = `${state.HTTP_URL}/lists`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const closeModal = () => {
    // dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
    dispatch({ type: "OVERLAY_RECRUIT_LISTS_STATUS", payload: false });
    // dispatch({ type: "RECRUIT_LISTS_STATUS", payload: false });
    document.body.classList.remove("overflow-hidden");
    onClose();
  };
  // open delete candidate modal
  const openDeleteCandidateModal = (e) => {
    // console.log("open Modal ID:", e.currentTarget.dataset.id);
    setDeleteCandidatePopup(true);
    document.body.classList.add("overflow-hidden");
  };
  // toggle Favorite Candidate
  const toggleFavoriteCandidate = async (e, type) => {
    const el = e.currentTarget;
    const url = `${state.HTTP_URL}/favorite`;
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const data = {
      candidate_id: candaditeModalData?.id,
    };
    try {
      const response = await axios.post(url, data, {
        headers: customHeaders,
      });
      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (type === "add") {
      el.nextSibling.classList.add("active");
      el.classList.remove("active");
    } else {
      el.previousSibling.classList.add("active");
      el.classList.remove("active");
    }
  };
  useEffect(() => {
    // keyboard events
    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft") {
        // Handle Arrow Left key press
        const selectedEl = document.querySelector(".candidate-image-wrapper");
        // selectedEl.classList.remove("selected");
        onSwitchCandidate(candaditeModalData?.id - 1, listID);
      } else if (event.key === "ArrowRight") {
        // Handle Arrow Right key press
        const selectedEl = document.querySelector(".candidate-image-wrapper");
        // selectedEl.classList.remove("selected");
        onSwitchCandidate(candaditeModalData?.id + 1, listID);
      } else if (event.key === "Enter") {
        toggleAddingToList();
      }
    };

    // Add event listeners when the component is mounted
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listeners when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [candaditeModalData?.id]);
  // console.log(candaditeModalData?.list_selected);
  return ReactDOM.createPortal(
    <div className={`candidate-modal ${open ? "is-open" : ""}`}>
      <button className="reset-btn close-modal" onClick={closeModal}>
        <i className="icon-close"></i>
      </button>
      {/* {children} */}
      <div className="row modal-content-container m-0">
        <DeleteCandidateModal
          open={deleteCandidatePopup}
          onClose={() => setDeleteCandidatePopup(false)}
        ></DeleteCandidateModal>
        <div
          className={`col-8 candidate-image-wrapper position-relative ${
            candaditeModalData?.list_selected ? "selected" : ""
          }`}
        >
          {state.RECRUIT_SIDE_NAME !== "submitted" && (
            <div className="actions-btns">
              <div className="favorites-icons w-100">
                <i
                  className={`icon-heart-blank icon addToFavorites ${
                    candaditeModalData?.favorite ? "" : "active"
                  }`}
                  onClick={(e) => toggleFavoriteCandidate(e, "add")}
                  // data-id={candidate.id}
                ></i>
                <i
                  className={`icon-heart-fill icon removeFromFavorites ${
                    candaditeModalData?.favorite ? "active" : ""
                  }`}
                  onClick={(e) => toggleFavoriteCandidate(e, "remove")}
                  // data-id={candidate.id}
                ></i>
              </div>
              <i
                className="icon-plus-circle icon addToList-btn d-block mt-3"
                onClick={toggleAddingToList}
                // data-id={candidate.id}
              ></i>
              {type !== "candidates" ? (
                <>
                  <i
                    className="icon-clear-btn icon delete-candadite-btn d-block mt-4"
                    onClick={openDeleteCandidateModal}
                  ></i>
                </>
              ) : null}
            </div>
          )}
          <div className="candidate-shape row justify-content-center">
            <div className="col-1 left-arrow-container d-flex align-items-center justify-content-start h-100">
              <i
                className="icon-left-arrow"
                onClick={() => {
                  onSwitchCandidate(candaditeModalData?.id - 1, listID);
                  const selectedEl = document.querySelector(
                    ".candidate-image-wrapper"
                  );
                  selectedEl.classList.remove("selected");
                }}
              ></i>
            </div>
            <ShowCandidate data={candaditeModalData} />
            <div className="col-1 right-arrow-container d-flex align-items-center justify-content-end h-100">
              <i
                className="icon-right-arrow"
                onClick={() => {
                  onSwitchCandidate(candaditeModalData?.id + 1, listID);
                  const selectedEl = document.querySelector(
                    ".candidate-image-wrapper"
                  );
                  selectedEl.classList.remove("selected");
                }}
                // onClick={() => nextCandidate(candaditeModalData?.id + 1)}
              ></i>
            </div>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="candidate-info-container px-4 py-5">
            <Profile data={candaditeModalData} />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default CandidateModal;
