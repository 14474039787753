import React, { useState, useEffect } from "react";
import UserProfileModal from "./UserModals/UserProfileModal";
import UserProfileModalCandidate from "./UserModals/UserProfileModalCandidate";
import NotificationsModal from "./UserModals/NotificationsModal";
import ContactModal from "./UserModals/ContactModal";
import { getSiblings } from "../../helpers/helpers";
// bootstrap datepicker
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js"; // import "bootstrap";
import $ from "jquery"; // Select2 relies on jQuery
import axios from "axios";
import { useAppContext } from "../../AppContext";
import "./UserControls.scss";
// Output: "MAY 1, 2024"
const convertDate = (inputDate) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateObject = new Date(inputDate);
  const formattedDate = dateObject.toLocaleDateString("en-US", options);
  return formattedDate;
};
// Output: "2024-12-20"
const convertDateToFormat = (inputDate) => {
  const dateObject = new Date(inputDate);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
function UserControls({ type }) {
  const { state, dispatch } = useAppContext();
  const [availability, setAvailability] = useState(null);
  const [availabilityOn, setAvailabilitOn] = useState(null);
  const [selectedDate, setSelectedDate] = useState(convertDate(new Date()));
  const USER_ROLE = localStorage.getItem("USER_ROLE");

  // Add a useEffect to listen for changes in the availability and selectedDate states
  useEffect(() => {
    if (USER_ROLE !== "business") {
      availabilityUpdateHandler();
    }
  }, [availabilityOn, selectedDate]);

  useEffect(() => {
    $(".datepicker").datepicker({
      format: "dd/mm/yyyy",
      autoclose: true,
      container: "#datepicker-container",
      orientation: "right",
    });

    $(".datepicker").on("changeDate", function (e) {
      setAvailability(0);
      setSelectedDate(convertDateToFormat(e.date));
      setAvailabilitOn(true);
      const selectedDate = e.date;
      const candidateAvailability = document.querySelector(
        ".candidate-availability"
      );
      candidateAvailability.innerHTML = `AVAILABLE ON ${convertDate(
        selectedDate
      )}`;
    });

    return () => {
      $(".datepicker").datepicker("destroy");
    };
  }, []);

  const togglePopOver = (e) => {
    e.target.closest(".status-popover").classList.toggle("popover--active");
  };

  const submitPopover = (e) => {
    const status = e.target.dataset.option;
    const candidateAvailability = document.querySelector(
      ".candidate-availability"
    );
    const classesList = "candidate-availability text-uppercase normal-text";
    candidateAvailability.innerHTML = status;
    if (status === "AVAILABLE") {
      setAvailability(1);
      setAvailabilitOn("false");
      candidateAvailability.className = `${classesList} available`;
    } else if (status === "NOT AVAILABLE") {
      setAvailability("0");
      setAvailabilitOn(false);
      candidateAvailability.className = `${classesList} not-available`;
    } else {
      $("#datepicker").datepicker("show");
      candidateAvailability.className = `${classesList} available-on`;
    }
    togglePopOver(e);
  };

  const handleActiveModal = (e) => {
    let el = document.querySelector(`.${e.target.dataset.activeModal}`);
    let siblings = getSiblings(el);
    siblings.map((c) => c.classList.remove("active"));
    if (el.classList.contains("active")) {
      el.classList.remove("active");
    } else {
      el.classList.add("active");
    }
  };
  const availabilityUpdateHandler = async () => {
    let data;
    if (availability !== null) {
      if (availability === 1 || !availabilityOn || availabilityOn === "false") {
        data = {
          availability,
        };
      } else if (availabilityOn) {
        data = {
          availability,
          availability_date: selectedDate,
        };
      }
      console.log(data);
    }
    // console.log(data);
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    // Define your custom headers here
    const customHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    try {
      const response = await axios.post(
        `${state.HTTP_URL}/candidate_update_availability`,
        data,
        {
          headers: customHeaders,
        }
      );
      console.log(response.data.data);
      const countries = response.data.data;
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center user-controls">
        {type === "candidate" && (
          <div className="status-popover">
            <div
              className="candidate-status icon popover__trigger"
              onClick={togglePopOver}
            >
              <span className="candidate-availability available text-uppercase normal-text">
                available
              </span>
            </div>
            <ul className="popover__menu">
              <li
                className="popover__menu-item normal-text"
                data-option="AVAILABLE"
                onClick={submitPopover}
              >
                AVAILABLE
              </li>
              <li
                className="popover__menu-item normal-text"
                data-option={`AVAILABLE ON ${selectedDate}`}
                onClick={submitPopover}
              >
                AVAILABLE ON
              </li>
              <li
                className="popover__menu-item normal-text"
                data-option="NOT AVAILABLE"
                onClick={submitPopover}
              >
                NOT AVAILABLE
              </li>
            </ul>
            <div id="datepicker-container">
              <input
                type="text"
                id="datepicker"
                className="datepicker"
                style={{ display: "none" }}
                value={selectedDate}
              />
            </div>
          </div>
        )}
        <i
          className={`icon-share ${type === "candidate" ? "" : `ms-0`}`}
          data-active-modal="contact-modal"
          onClick={handleActiveModal}
        ></i>
        <i
          className="icon-ring"
          data-active-modal="notification-modal"
          onClick={handleActiveModal}
        ></i>
        <i
          className="icon-user"
          data-active-modal="user-profile-modal"
          onClick={handleActiveModal}
        ></i>
      </div>
      <div className="user-modals-control">
        {USER_ROLE === "candidate" ||
          (USER_ROLE === "candicate" && <UserProfileModalCandidate />)}
        {USER_ROLE === "business" && <UserProfileModal />}
        <NotificationsModal />
        <ContactModal />
      </div>
    </>
  );
}

export default UserControls;
