import React, { useState, useEffect } from "react";
import { getSiblings } from "../../../../../helpers/helpers";
import "./RangeBar.scss";
let availabilityTxt = "0 MONTH";
function MultiRange(props) {
  const [firstSideTxt, setFirstSideTxt] = useState(0);
  const toggleactive = (e) => {
    let siblings = getSiblings(e);
    e.classList.remove("active");
    siblings[0].classList.add("active");
  };
  useEffect(() => {
    let sliderOne = document.getElementById(props.sliderOne);
    let percentTxt1;
    const slideOne = (e) => {
      if (e > 1) {
        percentTxt1 = e + " " + props.percent + "S";
      } else {
        percentTxt1 = e + " " + props.percent;
      }
      console.log(percentTxt1);
      availabilityTxt = percentTxt1;
      // setFirstSideTxt(percentTxt1);
    };

    sliderOne.addEventListener("input", (e) => {
      const x = parseInt(e.target.value);
      if (x === 0) {
        const title = document.querySelector(".personal-title .within");
        toggleactive(title);
      } else {
        const title = document.querySelector(".personal-title .now");
        toggleactive(title);
      }
      slideOne(x);
    });
    // slideOne(0);
  });

  const changeHandler = (e, id) => {
    if (e.target.type === "range") {
      props.onChangeHandler(e, props.title);
    }
  };

  return (
    <>
      <div className="wrapper one-range">
        <div className="range-container">
          <div className={`slider-track ${props.sliderTrack}`}></div>
          <div>
            {availabilityTxt !== "0 MONTH" && (
              <span
                id={props.rangeOne}
                className="percent-num from normal-text"
              >
                {availabilityTxt}
              </span>
            )}

            <input
              type="range"
              min="0"
              max={props.max}
              defaultValue="0"
              id={props.sliderOne}
              title={props.title}
              onChange={(e) => changeHandler(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MultiRange;
