import React, { useState, useEffect } from "react";
import axios from "axios";

let activityIdList = [];

function ApiTagsDataFetcher({
  apiUrl,
  title,
  inputType,
  number,
  oncheck,
  setFieldId,
  setActivityId,
  valueType,
  onChangeHandler,
}) {
  const [data, setData] = useState([]);
  // console.log(state.SAVED_SEARCH_LINK);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(apiUrl);
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  }, [apiUrl]);

  // onchange handler
  const changeHandler = (e, id) => {
    setFieldId && setFieldId(id);
    setActivityId && setActivityId(activityIdList);
    onChangeHandler(e, title);
  };

  // Store activity IDs
  const storeActivityIds = (e) => {
    const index = activityIdList.indexOf(e.target.dataset.id);
    if (index === -1) {
      activityIdList.push(e.target.dataset.id);
    } else {
      activityIdList.splice(index, 1);
    }
  };
  const industriesCheckox = (checkboxId) => {
    // Get the checkbox element
    var checkbox = document.getElementById(checkboxId);

    // If the clicked checkbox is already checked, uncheck it
    if (!checkbox.checked) {
      checkbox.checked = false;
    } else {
      let checkboxes = document.querySelectorAll(
        '#collapse-1 input[type="checkbox"]'
      );
      checkboxes.forEach(function (cb) {
        if (cb.id !== checkboxId) {
          cb.checked = false;
        }
      });
      // Then check the clicked checkbox
      checkbox.checked = true;
    }
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${number}`}>
        <button
          className="accordion-button collapsed pt-3 pb-0 px-0 text-uppercase normal-text"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${number}`}
          aria-expanded="false"
          aria-controls={`collapse-${number}`}
        >
          <span>{title}</span>
        </button>
      </h2>
      <div
        id={`collapse-${number}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading-${number}`}
        data-bs-parent="#tagsAccordion"
      >
        <div className="accordion-body">
          <div className="wraped-words">
            {data.map((item) => (
              <span className="text-uppercase" key={item.id}>
                <input
                  id={`${title}-${item.id}`}
                  type={inputType}
                  name={title}
                  data-id={item.id}
                  value={`${valueType}[]=${item.id}`}
                  onChange={(e) => {
                    setActivityId && storeActivityIds(e);
                    changeHandler(e, item.id);
                  }}
                  onClick={
                    title === "industry"
                      ? () => industriesCheckox(`${title}-${item.id}`)
                      : null
                  }
                />
                <label className="normal-text" htmlFor={`${title}-${item.id}`}>
                  {item.name}
                </label>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiTagsDataFetcher;
