import React from "react";
import { getSiblings } from "../../../helpers/helpers";
import { useAppContext } from "../../../AppContext";
import axios from "axios";
import "./SubmittedGrid.scss";

function SubmittedGrid({
  id,
  title,
  status,
  count,
  onGetSelectedSubmittedList,
  onSelectList,
  onSelectListHide,
  onGetListInfo,
}) {
  const { state, dispatch } = useAppContext();
  const recruitCandidatesList = state.RECRUIT_CANDIDATES_LIST;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  const selectListItemHandler = (e, activeList, title) => {
    toggleActiveClass(e);
    updateRecruitCandidateList(id, title);
    onGetSelectedSubmittedList(id);
    status === "submitted" ? onSelectList() : onSelectListHide();
    onGetListInfo(id, title);
  };
  const toggleActiveClass = (e) => {
    let el;
    e.currentTarget ? (el = e.currentTarget) : (el = e);
    let siblings = getSiblings(el);
    el.classList.add("active");
    siblings.map((e) => e.classList.remove("active"));
  };
  const updateRecruitCandidateList = (id, listName) => {
    const url = `${state.HTTP_URL}/list_candicates/${id}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          console.log(response.data.data);
          let candidatesInList = [];

          response.data.data.map((item) =>
            candidatesInList.push(item.candidate)
          );

          // Dispatch action to update RECRUIT_CANDIDATES_LIST
          dispatch({
            type: "RECRUIT_CANDIDATES_LIST",
            payload: candidatesInList,
          });
          dispatch({
            type: "SELECTED_LIST_NAME",
            payload: listName,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  };

  return (
    <div
      className={`submit-grid-container ${
        id === state.SELECTED_LIST_ID ? "active" : ""
      }`}
      data-id={id}
      onClick={(e) => selectListItemHandler(e, id, title)}
    >
      <div className="d-flex align-items-center justify-content-between mt-2 p-1">
        <div className="title normal-text">
          {`${title} ${count ? `(${count})` : ""}`}
        </div>
        {title && (
          <div className={`status normal-text text-uppercase ${status}`}>
            {status}
          </div>
        )}
      </div>
    </div>
  );
}

export default SubmittedGrid;
