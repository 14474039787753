import React from "react";
import ReactDOM from "react-dom";
import EditContact from "../../EditProfileContents/EditContact/EditContact";
import EditSocials from "../../EditProfileContents/EditSocials/EditSocials";
import EditWork from "../../EditProfileContents/EditWork/EditWork";
import EditEducation from "../../EditProfileContents/EditEducation/EditEducation";
import EditDocuments from "../../EditProfileContents/EditDocuments/EditDocuments";
import "./EditProfile.scss";
function EditProfile({
  type,
  open,
  onClose,
  onTabTypeHandler,
  onToggleActiveClass,
}) {
  return ReactDOM.createPortal(
    <>
      <div className={`edit-overlay ${open ? "in" : ""}`}></div>
      <div
        className={`candidate-modal custom-modal editProfile p-4 ${
          open && "is-open"
        }`}
      >
        <div className="edit-profile-tabs d-flex justify-content-end">
          <div
            className="contact-tab text-uppercase pointer me-3 text-light normal-text active"
            onClick={(e) => {
              onTabTypeHandler("contact");
              onToggleActiveClass(e);
            }}
          >
            Contact
          </div>
          <div
            className="socials-tab text-uppercase pointer me-3 text-light normal-text"
            onClick={(e) => {
              onTabTypeHandler("socials");
              onToggleActiveClass(e);
            }}
          >
            Socials
          </div>
          <div
            className="work-tab text-uppercase pointer me-3 text-light normal-text"
            onClick={(e) => {
              onTabTypeHandler("work");
              onToggleActiveClass(e);
            }}
          >
            Work
          </div>
          <div
            className="education-tab text-uppercase pointer me-3 text-light normal-text"
            onClick={(e) => {
              onTabTypeHandler("education");
              onToggleActiveClass(e);
            }}
          >
            Education
          </div>
          <div
            className="documents-tab text-uppercase pointer me-3 text-light normal-text"
            onClick={(e) => {
              onTabTypeHandler("documents");
              onToggleActiveClass(e);
            }}
          >
            Documents
          </div>
        </div>
        <div className="edit-content">
          {type === "contact" ? (
            <EditContact oncloseModal={onClose} />
          ) : type === "socials" ? (
            <EditSocials oncloseModal={onClose} />
          ) : type === "work" ? (
            <EditWork oncloseModal={onClose} />
          ) : type === "education" ? (
            <EditEducation oncloseModal={onClose} />
          ) : type === "documents" ? (
            <EditDocuments oncloseModal={onClose} />
          ) : null}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}

export default EditProfile;
