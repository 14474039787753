import React, { Fragment } from "react";

function Contacts(data) {
  console.log(data);
  return (
    <>
      {data ? (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap contact-wrapper">
            <div className="mb-2">
              {data.data.phone1 && (
                <div className="mb-2">
                  <i className="icon-whatsapp"></i>
                  {data.data.country_code && (
                    <span className="ms-2 normal-text phone">
                      {data.data.country_code}
                    </span>
                  )}
                  <span className="ms-2 normal-text phone">
                    {data.data.phone1}
                  </span>
                </div>
              )}
              {data.data.phone2 && (
                <div>
                  <i className="icon-whatsapp xwhats-green"></i>
                  {data.data.country_code && (
                    <span className="ms-2 normal-text phone">
                      {data.data.country_code}
                    </span>
                  )}
                  <span className="ms-2 normal-text phone">
                    {data.data.phone2}
                  </span>
                </div>
              )}
            </div>
            <div>
              <p className="mb-2 text-uppercase email">{data.data.email}</p>
              <div className="social-container text-xl-end">
                {data.data.facebook && (
                  <a href={data.data.facebook} target="_blank" className="me-2">
                    <i className="icon-facebook"></i>
                  </a>
                )}

                {data.data.linkedin && (
                  <a href={data.data.linkedin} target="_blank" className="me-2">
                    <i className="icon-linkedin"></i>
                  </a>
                )}
                {data.data.insta && (
                  <a href={data.data.insta} target="_blank" className="me-2">
                    <i className="icon-instagram"></i>
                  </a>
                )}
                {data.data.twitter && (
                  <a href={data.data.twitter} target="_blank">
                    <i className="icon-twitter"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <p className="text-center">No Tags added yet!</p>
      )}
      <hr />
    </>
  );
}

export default Contacts;
