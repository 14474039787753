import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
// bootstrap datepicker
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
// select2
import "select2/dist/css/select2.min.css";
import "../SharedStyle/SharedStyle.scss";
import "./EditEducation.scss";
import $ from "jquery"; // Select2 relies on jQuery
import "select2";

const EditEducation = ({ oncloseModal }) => {
  const { state, dispatch } = useAppContext();
  // states
  const [countriesArr, setCountriesArr] = useState([]);
  const [collectedData, setCollectedData] = useState({});
  const [certificateTitle, setCertificateTitle] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [country, setCountry] = useState("All");
  const [city, setCity] = useState("All");
  const [experience, setExperience] = useState(
    state.CANDIDATE_PROFILE.education
  );
  const [citesList, setCitesList] = useState([]);
  const [disableDatepicker, setDisableDatepicker] = useState(true);

  // refs
  const StartDatepickerRef = useRef(null);
  const endDatepickerRef = useRef(null);
  const countrySelectRef = useRef(null);
  const citySelectRef = useRef(null);
  // get countries list
  useEffect(() => {
    // Fetch countries from the API
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${state.HTTP_URL}/countries`);
        const countries = response.data.data;
        setCountriesArr(countries);

        // Set default cities based on the first country (you may adjust as needed)
        setCitesList(countries[0].cities);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);
  // functions
  const isFormValid = () => {
    return (
      certificateTitle.trim() !== "" &&
      universityName.trim() !== "" &&
      startDate.trim() !== "" &&
      endDate.trim() !== "" &&
      country.trim() !== "" &&
      city.trim() !== ""
    );
  };
  const certificateTitleHandler = (e) => {
    setCertificateTitle(e.target.value);
  };
  const universityNameHandler = (e) => {
    setUniversityName(e.target.value);
  };
  const endDateHandler = (e) => {
    if (e.target.checked) {
      setEndDate("NOW");
      setDisableDatepicker(false);
    } else {
      setEndDate("");
      setDisableDatepicker(true);
    }
  };
  // get month name
  const getMonthName = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    }

    return "";
  };

  const fetchCities = async (countryId) => {
    try {
      // Assuming your API endpoint to fetch cities takes the country ID as a parameter
      const response = await axios.get(`${state.HTTP_URL}/cites/${countryId}`);
      const cities = response.data.data;
      setCitesList(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Select2 init
  useEffect(() => {
    $(countrySelectRef.current).select2(); // Initialize Select2 on the select element
    $(citySelectRef.current).select2(); // Initialize Select2 on the select element

    // Add an event listener to handle changes in the selected value
    $(countrySelectRef.current).on("change", async (e) => {
      const selectedCountryId = e.target.value;

      if (selectedCountryId !== "All") {
        await fetchCities(selectedCountryId);
      } else {
        setCitesList(false);
      }

      setCountry(selectedCountryId);
    });

    $(citySelectRef.current).on("change", (e) => {
      setCity(e.target.value);
    });

    return () => {
      $(countrySelectRef.current).select2("destroy");
      $(citySelectRef.current).select2("destroy");
    };
  }, []);

  // Datepicker init
  useEffect(() => {
    // Initialize the date picker once the component is mounted
    $(StartDatepickerRef.current).datepicker({
      endDate: "today",
      autoclose: true,
    });
    $(endDatepickerRef.current).datepicker({
      endDate: "today",
      autoclose: true,
    });

    // Add an event listener to handle date changes
    $(StartDatepickerRef.current).on("changeDate", (e) => {
      // Get the selected month and year
      const selectedDate = new Date(e.target.value);
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      setStartDate(formattedDate);
    });
    $(endDatepickerRef.current).on("changeDate", (e) => {
      // Get the selected month and year
      const selectedDate = new Date(e.target.value);
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      setEndDate(formattedDate);
    });

    return () => {
      // Destroy the date picker when the component is unmounted
      $(StartDatepickerRef.current).datepicker("destroy");
      $(endDatepickerRef.current).datepicker("destroy");
    };
  }, []);
  // show cities
  let citesListContent;
  if (citesList) {
    citesListContent = citesList.map((city, index) => (
      <option data-name={city.name} value={city.id} key={city.id}>
        {city.name}
      </option>
    ));
  }
  console.log(citesListContent);

  // Click event Add Button
  const educationDataHandler = () => {
    let dataObj;
    if (isFormValid()) {
      const USER_ID = localStorage.getItem("USER_ID");
      if (endDate === "Now") {
        dataObj = {
          candidate_id: USER_ID,
          degree: certificateTitle,
          institute: universityName,
          from: startDate,
          country_id: country,
          city_id: city,
          status: "pending",
          currently_work: 1,
        };
      } else {
        dataObj = {
          candidate_id: USER_ID,
          degree: certificateTitle,
          institute: universityName,
          from: startDate,
          to: endDate,
          country_id: country,
          city_id: city,
          status: "pending",
          currently_work: 0,
        };
      }
      setCollectedData(dataObj);
      const updatedExperience = [dataObj, ...experience];
      console.log(dataObj);
      console.log(updatedExperience);
      // Set the updated array as the new state
      setExperience(updatedExperience);
      // Clear form fields after adding data
      setCertificateTitle("");
      setUniversityName("");
      setStartDate("");
      setEndDate("");
      setCountry("All");
      setCity("All");
    } else {
      alert("Please fill out all fields before adding.");
    }
  };
  const submitEducationDataHandler = () => {
    console.log(collectedData);
    // post request
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const url = `${state.HTTP_URL}/add_education`;
    axios
      .post(url, collectedData, {
        headers: customHeaders,
      })
      .then((response) => {
        console.log(response.data.data);
        oncloseModal();
        const url = `${state.HTTP_URL}/candidate_profile`;
        axios
          .post(
            url,
            {},
            {
              headers: customHeaders,
            }
          )
          .then((response) => {
            dispatch({
              type: "CANDIDATE_PROFILE",
              payload: response.data.data,
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const educationList = experience.map((education, index) => (
    <div
      className={`item-container ${
        index === experience.length - 1 ? "mb-3" : "mb-4"
      }`}
      key={index}
    >
      <div className="d-flex justify-content-between align-items-center profile-control">
        <p className="m-0 text-uppercase">
          <b>{education.degree}</b>
        </p>
        <p className="m-0 text-uppercase">
          {education.currently_work > 0 ? "Present" : education.to}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center profile-control">
        <p className="m-0">
          {education.school}, {education.country_name}, {education.city_name}
        </p>
        <p className="m-0 text-uppercase">{education.from}</p>
      </div>
    </div>
  ));

  // Show Countries list names
  const countriesList = countriesArr.map((country) => (
    <option value={country.id} key={country.id}>
      {country.name}
    </option>
  ));

  return (
    <div className="edit-education-wrap mt-4">
      <div className="new-education add-new-item">
        <div className="d-flex">
          <div className="input-control mb-3 col-6 pe-3">
            <input
              className="certificate-title"
              type="text"
              name="certificate-title"
              placeholder="CERTIFICATE TITLE"
              value={certificateTitle}
              onChange={(e) => certificateTitleHandler(e)}
            />
          </div>
          <div className="input-control mb-3 col-6 ps-4">
            <input
              className="university-name"
              type="text"
              name="university-name"
              placeholder="UNIVERSITY / SCHOOL"
              value={universityName}
              onChange={(e) => universityNameHandler(e)}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="date-control mb-3 col-5 pe-3">
            <input
              id="datepicker-input-start"
              type="text"
              ref={StartDatepickerRef}
              className="form-control"
              placeholder="START DATE"
              value={startDate}
            />
          </div>
          <div className="check-control mb-3 col-7 d-flex">
            <span
              className={`date-control col-8 pe-3 ${
                !disableDatepicker ? "disabled" : ""
              }`}
            >
              <input
                id="datepicker-input-end"
                type="text"
                ref={endDatepickerRef}
                className="form-control d-inline w-auto"
                placeholder="END DATE"
                value={endDate}
                disabled={!disableDatepicker}
              />
            </span>
            <div className="form-check col-4">
              <input
                type="checkbox"
                className="form-check-input pointer"
                id="current-education"
                // checked={endDate == "" ? false : true}
                onChange={(e) => endDateHandler(e)}
              />
              <label
                className="form-check-label pointer"
                htmlFor="current-education"
              >
                PRESENT
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="select-control mb-2 col-6 pe-3">
            <select
              id="country-selection"
              name="country-selection"
              ref={countrySelectRef}
              value={country}
            >
              {/* Add your options here */}
              <option value="All">Country</option>
              {countriesList}
            </select>
          </div>
          <div className="select-control mb-2 col-6 ps-4">
            <select
              id="city-selection"
              name="city-selection"
              ref={citySelectRef}
              value={city}
            >
              {/* Add your options here */}
              <option value="All">City</option>
              {citesListContent}
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3 add-new-item-action-btns">
          <button
            className="reset-btn submit-btn normal-text md-font"
            onClick={educationDataHandler}
          >
            ADD
          </button>
        </div>
      </div>
      <div className="work-history border-top pt-4 mb-4">{educationList}</div>
      <div className="d-flex justify-content-between edit-profile-action-btns edit-education">
        <button className="reset-btn normal-text" onClick={oncloseModal}>
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn normal-text md-font"
          onClick={submitEducationDataHandler}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default EditEducation;
