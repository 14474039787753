import React, { useState, useEffect } from "react";
import axios from "axios";
import BusinessControlBar from "../../Shared/BusinessControlBar/BusinessControlBar";
import CandidatesGrid from "../../Shared/CandidatesGrid/CandidatesGrid";
import Loader from "../../Shared/Loader/Loader";
import { useAppContext } from "../../../AppContext";
function CandidateRecruitCard(props) {
  const { state, dispatch } = useAppContext();
  const recruitCandidatesList = state.RECRUIT_CANDIDATES_LIST;
  const [isOpen, setIsOpen] = useState(false);
  const [title, settitle] = useState("Untitled Search");
  const [imgPos, setImgPos] = useState("");
  const [columns, setColumns] = useState("col-3");
  const [openSaved, setOpenSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const USER_ID = localStorage.getItem("USER_ID");
  const toggleSidebar = (status) => {
    setIsOpen(status);
  };

  const getSavedTitle = (txt) => {
    settitle(txt);
  };
  const toggleColumns = (cols) => {
    const colsNum = cols.target.value;
    if (colsNum === "2") {
      setImgPos("justify-content-center");
      setColumns("col-5");
    } else if (colsNum === "3") {
      setImgPos("");
      setColumns("col-4");
    } else {
      setImgPos("");
      setColumns("col-3");
    }
  };

  useEffect(() => {
    const url = `${state.HTTP_URL}/candidate/all?user_id=${USER_ID}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          // Dispatch action to update CANDIDATES_LIST
          dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
          dispatch({ type: "VIEW_OPTIONS_STATUS", payload: true });
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // open saved sidebar
  const openSavedSidebar = () => {
    setOpenSaved(true);
  };
  // close saved sidebar
  const closeSavedSidebar = () => {
    setOpenSaved(false);
  };
  return (
    <>
      <BusinessControlBar
        type={props.type}
        onClick={toggleSidebar}
        savedTitle={getSavedTitle}
        setColumnsHandler={toggleColumns}
        side={isOpen}
        // allNum={allLength}
        // maybeNum={maybeLength}
        // idealNum={idealLength}
        openSavedChecker={openSaved}
        // closeSaved={closeSavedSidebar}
      />
      <CandidatesGrid
        side={isOpen}
        columns={columns}
        title={title}
        imgPosClass={imgPos}
        type={props.type}
        // candidates={candidates}
        // maybeCounter={maybeListCount}
        // idealCounter={idealListCount}
        // onSavedOpen={openSavedSidebar}
      />
      {loading && <Loader />}
    </>
  );
}

export default CandidateRecruitCard;
