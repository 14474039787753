import React, { useState } from "react";
import "./Message.scss";
const Message = ({ type, msg }) => {
  return (
    <div className="message-container">
      <div
        className="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <strong className="me-auto">{type}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body">{msg}</div>
      </div>
    </div>
  );
};

export default Message;
