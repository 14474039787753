import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import "./userModals.scss";
let selectedIDs = [];

function NotificationsModal() {
  const [actions, setActions] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { state } = useAppContext();
  const notificationsUrl = `${state.HTTP_URL}/notifications`;
  const deleteNotificationUrl = `${state.HTTP_URL}/delete_notifications`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  // Define your custom headers here
  const customHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  const getItemsIdsArr = (e) => {
    const input = e.currentTarget.querySelector("input");
    const notificationID = e.currentTarget.dataset.id;
    const intValue = parseInt(notificationID);
    // check if the ID in the array
    if (selectedIDs.includes(intValue)) {
      var index = selectedIDs.indexOf(intValue);
      selectedIDs.splice(index, 1);
      input.checked = false;
    } else {
      selectedIDs.push(intValue);
      input.checked = true;
    }

    if (selectedIDs.length) {
      setActions(true);
    } else {
      setActions(false);
    }
  };
  const closeUserModal = () => {
    const navUserModal = document.querySelector(".nav_user_modal.active");
    navUserModal.classList.remove("active");
  };

  useEffect(() => {
    // Define an async function to fetch the data
    async function getnotifications() {
      try {
        const response = await axios.post(
          notificationsUrl,
          {},
          {
            headers: customHeaders,
          }
        );
        setNotifications(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error sending data to the backend:", error);
        throw error; // You can handle the error in the calling component
      }
    }
    getnotifications();
  }, []);

  const removeFromNotifications = async () => {
    try {
      await axios.post(
        deleteNotificationUrl,
        {
          notificationIds: selectedIDs,
        },
        {
          headers: customHeaders,
        }
      );

      // Fetch updated notifications after successful deletion
      const response = await axios.post(
        notificationsUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      setNotifications(response.data);
      setActions(false);
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  return (
    <div className="nav_user_modal notification-modal col-3">
      <button
        className="reset-btn close-modal position-static mb-4 ms-auto d-block px-4 normal-text"
        onClick={closeUserModal}
      >
        <i className="icon-close"></i>
      </button>
      <h4 className="modal-title mb-4 text-uppercase bold-text">
        Notifications
      </h4>
      <div className={`notifications-control ${actions && "active"}`}>
        <button
          className="reset-btn submit-btn px-2 py-1 delete-notifications red-btn mb-3 text-uppercase normal-text"
          onClick={removeFromNotifications}
        >
          Delete
        </button>
      </div>
      <hr className="mt-1 mb-4 mx-4" />
      <div className="notifications-container">
        {notifications ? (
          notifications.map((item, index) => (
            <div className="notifications_items" key={index}>
              <div className="notification-day mt-4 normal-text">
                {item.date}
              </div>
              {item.data.map((c, i) => (
                <div
                  className="notification-item d-flex justify-content-between align-items-center"
                  key={c.id}
                  data-id={c.id}
                  onClick={getItemsIdsArr}
                >
                  <div className="d-flex align-items-center notification-box">
                    <input id={`select-notification-${c.id}`} type="checkbox" />
                    <span className="notification-title mb-0 normal-text">
                      {c.title}
                    </span>
                  </div>
                  <p className="notification-time mb-0 normal-text">{c.time}</p>
                </div>
              ))}
            </div>
          ))
        ) : (
          <p>No Notifications</p>
        )}
      </div>
    </div>
  );
}

export default NotificationsModal;
