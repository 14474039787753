import React, { useState } from "react";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import "./CreateList.scss";
function CreateList(props) {
  const [newList, setNewList] = useState([]);
  const { state, dispatch } = useAppContext();

  // Post Request
  const url = `${state.HTTP_URL}/create_list`;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const saveNewList = () => {
    console.log("saving");
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    const data = {
      name: newList,
    };
    axios
      .post(url, data, {
        headers: customHeaders,
      })
      .then((response) => {
        console.log(response.data.data);
        closeCreateList();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // update Lists
    const listsUrl = `${state.HTTP_URL}/lists`;
    axios
      .post(
        listsUrl,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const closeCreateList = () => {
    props.onClose();
  };
  return (
    <section className="create-list-container">
      <h2 className="mb-4 bold-text">Create New List</h2>
      <input
        className="set-name input-reset bordered-input"
        placeholder="List Name"
        type="text"
        onChange={(e) => setNewList(e.target.value)}
        maxLength="30"
      />
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="reset-btn submit-btn red-btn p-2"
          onClick={closeCreateList}
        >
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn p-2 text-uppercase"
          onClick={saveNewList}
        >
          SAVE
        </button>
      </div>
    </section>
  );
}

export default CreateList;
