import React, { useState, useRef } from "react";
import axios from "axios";
import { convertDateFormat } from "../../../../helpers/helpers";
import "./EditDocuments.scss";

const EditDocuments = ({ oncloseModal }) => {
  const [idPassportFile, setIdPassportFile] = useState(null);
  const [medicalFile, setMedicalFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [certificateFile, setCertificateFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileChange = (file, setFile) => {
    setFile(file);
    console.log(file.lastModifiedDate);
  };

  const handleFileUpload = (file, documentType) => {
    const formData = new FormData();
    const USER_ID = localStorage.getItem("USER_ID");
    formData.append("candidate_id", USER_ID);
    formData.append("file", file);

    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");

    const config = {
      headers: {
        Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        `https://dashboard.starlinestepper.com/api/add_candidate_files/${documentType}`,
        formData,
        config
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleFileInput = (file, setFile, documentType) => {
    handleFileChange(file, setFile);

    // Optionally, you can call handleFileUpload here if you want to upload the file immediately upon selection
    if (file) {
      handleFileUpload(file, documentType);
    }
  };

  return (
    <div className="edit-documents mt-4">
      {/* ID/PASSPORT */}
      <div className="document-item d-flex justify-content-between">
        <div className="documnet-name">ID/PASSPORT</div>
        <div className="documnet-upload-file">
          <label htmlFor="idPassportInput" className="upload-btn reset-btn">
            Upload
          </label>
          <input
            id="idPassportInput"
            ref={fileInputRef}
            className="upload-document"
            type="file"
            onChange={(e) =>
              handleFileInput(e.target.files[0], setIdPassportFile, "passport")
            }
          />
        </div>
      </div>
      {/* Display file info */}
      {idPassportFile && (
        <div className="document-info d-flex justify-content-between mx-5 my-4">
          <div className="documnet-name">{idPassportFile.name}</div>
          <div className="documnet-name">
            {convertDateFormat(new Date(idPassportFile.lastModified))}
          </div>
        </div>
      )}

      {/* MEDICAL */}
      <div className="document-item d-flex justify-content-between">
        <div className="documnet-name">MEDICAL</div>
        <div className="documnet-upload-file">
          <label htmlFor="medicalInput" className="upload-btn reset-btn">
            Upload
          </label>
          <input
            id="medicalInput"
            className="upload-document"
            type="file"
            onChange={(e) =>
              handleFileInput(e.target.files[0], setMedicalFile, "medical")
            }
          />
        </div>
      </div>
      {/* Display file info */}
      {medicalFile && (
        <div className="document-info d-flex justify-content-between mx-5 my-4">
          <div className="documnet-name">{medicalFile.name}</div>
          <div className="documnet-name">
            {convertDateFormat(new Date(medicalFile.lastModified))}
          </div>
        </div>
      )}

      {/* RESUME */}
      <div className="document-item d-flex justify-content-between">
        <div className="documnet-name">RESUME</div>
        <div className="documnet-upload-file">
          <label htmlFor="resumeInput" className="upload-btn reset-btn">
            Upload
          </label>
          <input
            id="resumeInput"
            className="upload-document"
            type="file"
            onChange={(e) =>
              handleFileInput(e.target.files[0], setResumeFile, "resume")
            }
          />
        </div>
      </div>
      {/* Display file info */}
      {resumeFile && (
        <div className="document-info d-flex justify-content-between mx-5 my-4">
          <div className="documnet-name">{resumeFile.name}</div>
          <div className="documnet-name">
            {convertDateFormat(new Date(resumeFile.lastModified))}
          </div>
        </div>
      )}

      {/* CERTIFICATE */}
      <div className="document-item d-flex justify-content-between">
        <div className="documnet-name">CERTIFICATE</div>
        <div className="documnet-upload-file">
          <label htmlFor="certificateInput" className="upload-btn reset-btn">
            Upload
          </label>
          <input
            id="certificateInput"
            className="upload-document"
            type="file"
            onChange={(e) =>
              handleFileInput(
                e.target.files[0],
                setCertificateFile,
                "certificate"
              )
            }
          />
        </div>
      </div>
      {/* Display file info */}
      {certificateFile && (
        <div className="document-info d-flex justify-content-between mx-5 my-4">
          <div className="documnet-name">{certificateFile.name}</div>
          <div className="documnet-name">
            {convertDateFormat(new Date(certificateFile.lastModified))}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-between edit-profile-action-btns edit-documents">
        <button className="reset-btn normal-text" onClick={oncloseModal}>
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn normal-text md-font"
          // onClick={socialsDataHandler}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default EditDocuments;
