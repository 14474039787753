import React, { useState, useEffect } from "react";
import AddToList from "../Modals/AddToList/AddToList";
import SubmitList from "../Modals/SubmitList/SubmitList";
import axios from "axios";
import $ from "jquery";
import "./BusinessControlBar.scss";
import {
  getSiblings,
  imageChangeHandler,
  closeSideBar,
} from "../../../helpers/helpers";
import { useAppContext } from "../../../AppContext";
import FilterActions from "../FilterActions/FilterActions";

function BusinessControlBar(props) {
  const [isAddToListOpen, setIsAddToListOpen] = useState(false);
  const [isSubmitListOpen, setIsSubmitListOpen] = useState(false);
  const [isSubmitListBtn, setIsSubmitListBtn] = useState(false);
  const { state, dispatch } = useAppContext();
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const USER_ID = localStorage.getItem("USER_ID");
  useEffect(() => {
    document.body.onclick = () => {
      $(".popover").removeClass("popover--active");
    };
  }, []);
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  // const [breadcrumb, setBreadcrumb] = useState(false);
  const togglePopOver = (e) => {
    e.stopPropagation();
    e.target.closest(".popover").classList.toggle("popover--active");
  };

  const submitPopover = (e) => {
    togglePopOver(e);
    $(e.currentTarget).addClass("active").siblings().removeClass("active");
    $(e.currentTarget)
      .find(".active")
      .removeClass("active")
      .siblings()
      .addClass("active");
    // Determine the order type based on the presence of 'active' class
    var orderType = $(e.currentTarget)
      .find("i.active")
      .hasClass("icon-arrow-up-solid")
      ? "desc"
      : "asc";
    const urlParms = `search_order_by=${e.currentTarget.dataset.option}&search_order_type=${orderType}&`;

    if (props.type === "candidates") {
      fetchDataCandidatesList();
    } else {
      fetchDataRecruitList(e, orderType);
    }
    // Define an async function to fetch the data
    async function fetchDataCandidatesList() {
      const url = `${state.HTTP_URL}/candidate/all?${urlParms}list_id=${state.ACTIVE_LIST_ID}&user_id=${USER_ID}`;
      console.log(url);
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          console.log(response.data.data);
          // Dispatch action to update CANDIDATES_LIST
          dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  // Define an async function to fetch the data
  const fetchDataRecruitList = async (e, type) => {
    let urlParms = "";
    if (e) {
      urlParms = `?search_order_by=${e.currentTarget.dataset.option}&search_order_type=${type}`;
    }
    console.log(urlParms);
    let url = `${state.HTTP_URL}/list_candicates/${state.SELECTED_LIST_ID}${urlParms}`;
    console.log(url);
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    try {
      const response = await axios.get(url, {
        headers: customHeaders,
      });
      if (response.status === 200) {
        console.log(response.data.data);
        // Ensure response.data.data is always treated as an array
        const responseData = Array.isArray(response.data.data)
          ? response.data.data
          : Object.values(response.data.data);

        let candidatesInList = [];
        console.log(responseData);
        responseData.map((item) => candidatesInList.push(item.candidate));
        // Dispatch action to update RECRUIT_CANDIDATES_LIST
        dispatch({
          type: "RECRUIT_CANDIDATES_LIST",
          payload: candidatesInList,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    const popover = document.querySelector(".filters-actions .popover");
    if (popover) {
      document.body.addEventListener("click", () => {
        if (popover.classList.contains("popover--active")) {
          popover.classList.remove("popover--active");
        }
      });
    }
  }, []);
  const setActiveClass = (e) => {
    const el = e.currentTarget;
    let siblings = getSiblings(el);
    el.classList.add("active");
    // siblings[0].classList.add("active");
    siblings.map((x) => x.classList.remove("active"));
  };

  const openAtToListModal = (e) => {
    dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
    dispatch({ type: "RECRUIT_LISTS_STATUS", payload: true });
  };
  const openSubmitListModal = (e) => {
    if (state.IDEAL_LIST_LENGTH > 0) {
      const url = `${state.HTTP_URL}/list_info/${state.SELECTED_LIST_ID}`;
      axios
        .post(
          url,
          {},
          {
            headers: customHeaders,
          }
        )
        .then((response) => {
          // Dispatch action to update CANDIDATES_CONTAINER_STATUS
          console.log(response.data.data);
          dispatch({ type: "SUBMITED_LIST_INFO", payload: response.data.data });
          dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
          dispatch({ type: "SUBMIT_LIST_STATUS", payload: true });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      alert("Please add at least 1 candidate in Ideal list");
    }
  };

  // switch list tabs
  const listTabsHandler = (e, type) => {
    setActiveClass(e);
    dispatch({ type: "MOVE_ALL_BTN", payload: false });
    if (type === "maybe") {
      checkCandidateItemClass(type);
      dispatch({ type: "SUBMIT_LIST_BTN", payload: false });
      dispatch({ type: "TAB_LIST_NAME", payload: "maybe-list" });
    } else if (type === "ideal") {
      checkCandidateItemClass(type);
      dispatch({ type: "SUBMIT_LIST_BTN", payload: true });
      dispatch({ type: "TAB_LIST_NAME", payload: "ideal-list" });
    } else {
      checkCandidateItemClass(type);
      dispatch({ type: "SUBMIT_LIST_BTN", payload: false });
      dispatch({ type: "TAB_LIST_NAME", payload: "pending-list" });
    }
  };
  const sidebarHandler = (e, sideName) => {
    if (sideName === "tags" || sideName === "saved") {
      if (state.CANDIDATES_SIDE_NAME === sideName) {
        dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
        dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: false });
        dispatch({ type: "CANDIDATES_SIDE_NAME", payload: "" });
        $(e.currentTarget).removeClass("active");
      } else {
        dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
        dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: true });
        dispatch({ type: "CANDIDATES_SIDE_NAME", payload: sideName });
        if (e) {
          $(e.currentTarget)
            .addClass("active")
            .siblings()
            .removeClass("active");
        }
      }
    } else {
      if (state.RECRUIT_SIDE_NAME === sideName) {
        dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
        dispatch({ type: "RECRUIT_SIDE_STATUS", payload: false });
        dispatch({ type: "RECRUIT_SIDE_NAME", payload: "" });
        $(e.currentTarget).removeClass("active");
      } else {
        dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
        dispatch({ type: "RECRUIT_SIDE_STATUS", payload: true });
        dispatch({ type: "RECRUIT_SIDE_NAME", payload: sideName });
        if (e) {
          $(e.currentTarget)
            .addClass("active")
            .siblings()
            .removeClass("active");
        }
      }
    }
  };
  useEffect(() => {
    // if (localStorage.getItem("sidebar-status") === "false") {
    closeSideBar(props.onClick);
    // }
    if (props.openSavedChecker) {
      sidebarHandler(null, "saved", props.onClick, props.savedTitle);
      // props.closeSaved();
    }
  }, [props.openSavedChecker]);

  // Check active classes
  const checkCandidateItemClass = (tab) => {
    if ($(".candidate-item").hasClass("hide")) {
      $(".candidate-item").removeClass("hide");
    }
    if (tab === "ideal") {
      $(".candidate-item:not(.ideal)").toggleClass("hide");
    } else if (tab === "maybe") {
      $(".candidate-item:not(.maybe)").toggleClass("hide");
    }
  };

  return (
    <div className="control-bar-container px-3">
      <div className="p-0 position-relative">
        <div className="row w-100 justify-content-between">
          {props.type === "candidates" ? (
            <>
              <div className="col-4 breadcrumb-container">
                <div className="row align-items-center breadcrumb mb-0">
                  {state.SAVED_SEARCH_STATUS && (
                    <p className="mb-0 col-auto custom-w">
                      {state.SAVED_SEARCH_NAME}
                    </p>
                  )}

                  {state.ACTIVE_LIST_ID && state.SAVED_SEARCH_STATUS ? (
                    <i className="icon-right-arrow-alt col-auto text-center"></i>
                  ) : (
                    ""
                  )}
                  {state.ACTIVE_LIST_ID && (
                    <div className="col-7">
                      <p className="mb-0 col-4 custom-w">
                        {state.ACTIVE_LIST_NAME}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="col-4 d-flex">
              <div className="col-4">
                <span className="normal-text">{state.SELECTED_LIST_NAME}</span>
              </div>
              {state.SELECTED_LIST_NAME &&
                state.RECRUIT_SIDE_NAME !== "submitted" &&
                state.SELECTED_LIST_NAME !== "FAVORITES" &&
                state.RECRUIT_CANDIDATES_LIST.length > 0 && (
                  <div className="col-8 d-flex">
                    <div
                      className="list-tab all-tab me-3 active"
                      onClick={(e) => listTabsHandler(e, "all")}
                    >
                      <span className="me-1 normal-text">ALL</span>
                      <span className="list-number">
                        ({state.ALL_LIST_LENGTH})
                      </span>
                    </div>
                    <div
                      className="list-tab maybe-tab me-3"
                      onClick={(e) => listTabsHandler(e, "maybe")}
                    >
                      <span className="me-1 normal-text">MAYBE</span>
                      <span className="list-number">
                        ({state.MAYBE_LIST_LENGTH})
                      </span>
                    </div>
                    <div
                      className="list-tab ideal-tab"
                      onClick={(e) => listTabsHandler(e, "ideal")}
                    >
                      <span className="me-1 normal-text">IDEAL</span>
                      <span className="list-number">
                        ({state.IDEAL_LIST_LENGTH})
                      </span>
                    </div>
                  </div>
                )}
            </div>
          )}
          <div className="col-4">
            {state.PAGE_NAME === "candidates" &&
              state.CANDIDATES_LIST.length > 0 && (
                <FilterActions
                  onTogglePopOver={togglePopOver}
                  onSubmitPopover={submitPopover}
                  onChangeHandler={(e) => {
                    props.setColumnsHandler(e);
                  }}
                />
              )}
            {state.SELECTED_LIST_ID &&
              state.PAGE_NAME === "recruit" &&
              state.RECRUIT_CANDIDATES_LIST.length > 0 && (
                <FilterActions
                  onTogglePopOver={togglePopOver}
                  onSubmitPopover={submitPopover}
                  onChangeHandler={(e) => {
                    props.setColumnsHandler(e);
                  }}
                />
              )}
          </div>
          <div className="col-4 row p-0">
            {props.type === "candidates" ? (
              <>
                <div className="col-7 p-0 add-list-container">
                  <i
                    className="icon-plus icon add-list-icon"
                    onClick={openAtToListModal}
                  ></i>
                  <span
                    className="text-uppercase add-list-title normal-text"
                    onClick={openAtToListModal}
                  >
                    RECRUIT LISTS
                  </span>
                  <span className="list-title"></span>
                </div>
                <div className="col-5 p-0 text-end side-bar-control">
                  <span
                    className={`open-tags text-uppercase pointer me-3 normal-text ${
                      state.CANDIDATES_SIDE_NAME === "tags" ? "active" : ""
                    }`}
                    onClick={(e) =>
                      sidebarHandler(e, "tags", props.onClick, props.savedTitle)
                    }
                  >
                    Tags
                  </span>
                  <span
                    className={`saved-lists text-uppercase pointer normal-text ${
                      state.CANDIDATES_SIDE_NAME === "saved" ? "active" : ""
                    }`}
                    onClick={(e) =>
                      sidebarHandler(
                        e,
                        "saved",
                        props.onClick,
                        props.savedTitle
                      )
                    }
                  >
                    Saved
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="col-6 p-0 add-list-container">
                  {state.SUBMIT_LIST_BTN &&
                    state.RECRUIT_CANDIDATES_LIST.length > 0 && (
                      <button
                        className="text-uppercase reset-btn blue-btn ps-2 normal-text"
                        onClick={openSubmitListModal}
                      >
                        Submit List
                      </button>
                    )}
                  <span className="list-title"></span>
                </div>
                <div className="col-6 p-0 text-end side-bar-control">
                  <span
                    className={`open-lists text-uppercase pointer me-3 normal-text ${
                      state.RECRUIT_SIDE_NAME === "lists" ? "active" : ""
                    }`}
                    onClick={(e) =>
                      sidebarHandler(
                        e,
                        "lists",
                        props.onClick,
                        props.savedTitle
                      )
                    }
                  >
                    Lists
                  </span>
                  <span
                    className={`submitted-lists text-uppercase pointer normal-text ${
                      state.RECRUIT_SIDE_NAME === "submitted" ? "active" : ""
                    }`}
                    onClick={(e) =>
                      sidebarHandler(
                        e,
                        "submitted",
                        props.onClick,
                        props.savedTitle
                      )
                    }
                  >
                    Submitted
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <AddToList
          open={isAddToListOpen}
          oncloseSideBar={() => closeSideBar(props.onClick)}
          onClose={() => {
            setIsAddToListOpen(false);
            props.onClick(false);
          }}
        />
        <SubmitList
          open={isSubmitListOpen}
          oncloseSideBar={() => closeSideBar(props.onClick)}
          onClose={() => {
            setIsSubmitListOpen(false);
            props.onClick(false);
          }}
        />
      </div>
    </div>
  );
}

export default BusinessControlBar;
