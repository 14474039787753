import React, { Fragment } from "react";
import "./Fields.scss";

function Fields({ data }) {
  let tagList;
  console.log(data);
  if (data) {
    tagList = data.map((tag, index) => (
      <Fragment key={index}>
        <span className="text-uppercase normal-text">{tag.field_name}</span>
      </Fragment>
    ));
  }
  return (
    <>
      {data.length ? (
        <div className="fields-container wraped-words">{tagList}</div>
      ) : (
        <p className="text-center">No Tags added yet!</p>
      )}
      <hr />
    </>
  );
}

export default Fields;
