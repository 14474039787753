import React, { useState } from "react";
import axios from "axios";
import Loader from "../Shared/Loader/Loader";
import Message from "../Shared/Modals/Message/Message";
import { useAppContext } from "../../AppContext";
import "./Home.scss";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { state, dispatch } = useAppContext();

  const url = `${state.HTTP_URL}/candidate/login`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
    };
    setLoading(true);
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Handle the response here
      localStorage.setItem("LoggedIn", "true");
      localStorage.setItem("USER_ROLE", response.data.data.user_type);
      localStorage.setItem("USER_ID", response.data.data.user_id);
      localStorage.setItem(
        "YOUR_ACCESS_TOKEN",
        response.data.data.access_token
      );
      window.location.reload();
      // console.log(response.data.data.access_token);
    } catch (error) {
      localStorage.setItem("LoggedIn", "false");
      // Handle errors here
      setError(error.response.data.msg);
      setMessage(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <div className="form-container">
        <h2 className="text-center">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label htmlFor="inputEmail" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="inputPassword" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="mt-3">
            Login
          </button>
        </form>
        {loading && <Loader />}
      </div>
      {message && <Message type="Error" msg={error} />}
    </>
  );
};

export default Home;
