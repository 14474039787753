import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
import "./SubmitList.scss";
function SubmitList({ open, onClose, oncloseSideBar }) {
  const [submitListThanks, setSubmitListThanks] = useState(false);
  const [withdrawListConfirm, setWithdrawListConfirm] = useState(false);
  const [withdrawListThanks, setWithdrawListThanks] = useState(false);
  const { state, dispatch } = useAppContext();

  // Post Request
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const submitListUrl = `${state.HTTP_URL}/submit_list/${state.SELECTED_LIST_ID}`;
  const withdrawListUrl = `${state.HTTP_URL}/withdraw_list/${state.SELECTED_LIST_ID}`;
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };

  let navigate = useNavigate();
  const navigateToConcierge = () => {
    navigate("/concierge");
  };
  const closeAll = () => {
    // onClose();
    // oncloseSideBar();
    resetList();
    // dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
    dispatch({ type: "SUBMIT_LIST_STATUS", payload: false });
    dispatch({ type: "TAB_LIST_NAME", payload: "pending-list" });
    // dispatch({ type: "SIDE_STATUS", payload: false });
    if (state.RECRUIT_SIDE_STATUS) {
      dispatch({ type: "RECRUIT_SIDE_STATUS", payload: true });
    } else {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
      dispatch({ type: "RECRUIT_SIDE_STATUS", payload: false });
      dispatch({ type: "RECRUIT_SIDE_NAME", payload: "" });
    }
  };
  // reset list
  const resetList = () => {
    setSubmitListThanks(false);
    setWithdrawListConfirm(false);
    setWithdrawListThanks(false);
  };

  // open list thanks
  const listThanks = () => {
    submitListHandler();
  };
  const submitListHandler = async () => {
    try {
      const response = await axios.post(
        submitListUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      if (response.status === 200) {
        resetList();
        setSubmitListThanks(true);
        // Dispatch action to update RECRUIT_CANDIDATES_LIST
        dispatch({
          type: "RECRUIT_CANDIDATES_LIST",
          payload: [],
        });
        dispatch({
          type: "SELECTED_LIST_NAME",
          payload: "",
        });
        // dispatch({
        //   type: "SELECTED_LIST_ID",
        //   payload: null,
        // });
        dispatch({ type: "SUBMIT_LIST_BTN", payload: false });
        dispatch({ type: "TAB_LIST_NAME", payload: "maybe-list" });
        // console.log(response.data.data);
        updateRecruitLists();
        updateSubmittedLists();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const updateRecruitLists = () => {
    const url = `${state.HTTP_URL}/lists`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        // setLists(response.data.data);
        dispatch({ type: "LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateSubmittedLists = () => {
    const url = `${state.HTTP_URL}/lists_submitted`;
    axios
      .post(
        url,
        {},
        {
          headers: customHeaders,
        }
      )
      .then((response) => {
        dispatch({ type: "SUBMITTED_LISTS_LIST", payload: response.data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const withdrawListHandler = async () => {
    try {
      const response = await axios.post(
        withdrawListUrl,
        {},
        {
          headers: customHeaders,
        }
      );
      if (response.status === 200) {
        setWithdrawListThanks(true);
        updateRecruitLists();
        updateSubmittedLists();
        console.log(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // open withdraw confirm
  const withdrawConfirm = () => {
    resetList();
    setWithdrawListConfirm(true);
  };

  // open withdraw thanks
  const withdrawThanks = () => {
    resetList();
    withdrawListHandler();
  };

  // open concierge
  const goToConcierge = () => {
    resetList();
    navigateToConcierge();
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  let countryItems;
  if (state.SUBMITED_LIST_INFO.country_counts) {
    countryItems = Object.entries(state.SUBMITED_LIST_INFO.country_counts).map(
      ([country, count]) => (
        <li key={country} className="mb-2">
          {count} From {capitalizeFirstLetter(country)}
        </li>
      )
    );
  }
  let content;
  if (submitListThanks) {
    content = (
      <div className="submit-list-container">
        <h4 className="bold-text">THANK YOU</h4>
        <p className="mt-3 mb-3">Your list has been submitted.</p>
        <p className="mt-3 mb-3">You can WITHDRAW the list within 6 hours</p>

        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn red-btn normal-text"
            type="button"
            value="WITHDRAW"
            onClick={withdrawConfirm}
          />
          <input
            className="reset-btn submit-btn normal-text"
            type="button"
            value="CLOSE"
            onClick={closeAll}
          />
        </div>
      </div>
    );
  } else if (withdrawListConfirm) {
    content = (
      <div className="submit-list-container">
        <h4 className="bold-text">WANT TO WITHDRAW?</h4>
        <h5 className="mt-3 mb-3 sm-bold-text">List Name</h5>
        <p>
          This action will return your selection to Lists section and our team
          will stop processing your request.
        </p>
        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn normal-text"
            type="button"
            value="CANCEL"
            onClick={closeAll}
          />
          <input
            className="reset-btn submit-btn red-btn normal-text"
            type="button"
            value="WITHDRAW"
            onClick={withdrawThanks}
          />
        </div>
      </div>
    );
  } else if (withdrawListThanks) {
    content = (
      <div className="submit-list-container">
        <h4 className="bold-text">WITHDRAWN!</h4>
        <p className="mt-3 mb-3">
          You can resubmit anytime from your Lists menu.
        </p>
        <p className="mt-3 mb-3">
          or click on concierge below and we will take care of your task for
          you.
        </p>
        <p className="mt-3 mb-3"> Good luck with your next search!</p>
        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn normal-text"
            type="button"
            value="CLOSE"
            onClick={closeAll}
          />
          <input
            className="reset-btn submit-btn normal-text"
            type="button"
            value="CONCIERGE"
            onClick={goToConcierge}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div className="submit-list-container">
        <h4 className="bold-text mb-4">YOU ARE SUBMITTING</h4>
        <h5 className="mt-3 mb-4 sm-3 sm-bold-text">
          {state.SUBMITED_LIST_INFO.name}
        </h5>
        <ul className="normal-text">
          <li className="mb-2">
            {state.SUBMITED_LIST_INFO.candidates_count} Candidates
          </li>
          <li className="mb-2">
            {state.SUBMITED_LIST_INFO.female_count > 0 &&
              `${state.SUBMITED_LIST_INFO.female_count} Female and `}
            {state.SUBMITED_LIST_INFO.male_count > 0 &&
              `${state.SUBMITED_LIST_INFO.male_count} Male`}
          </li>

          {countryItems}
        </ul>
        <div className="d-flex justify-content-between align-items-center profile-control pt-3">
          <input
            className="reset-btn normal-text"
            type="button"
            value="CANCEL"
            onClick={closeAll}
          />
          <input
            className="reset-btn submit-btn blue-btn normal-text"
            type="button"
            value="SUBMIT"
            onClick={listThanks}
          />
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div
      className={`candidate-modal custom-modal submitList p-4 ${
        state.SUBMIT_LIST_STATUS ? "is-open" : ""
      }`}
    >
      <button
        className="reset-btn close-modal position-static mb-4"
        onClick={closeAll}
      >
        <i className="icon-close"></i>
      </button>
      {content}
    </div>,
    document.getElementById("portal")
  );
}

export default SubmitList;
