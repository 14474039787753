import React, { useEffect } from "react";
import UserControls from "./UserControls";
import logo from "../../assets/imgs/logo-black.png";
import { useAppContext } from "../../AppContext";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

// import { closeSideBar } from "../../helpers/helpers";
function NavbarContainer({ type }) {
  const { state, dispatch } = useAppContext();
  const closeSideBar = (name) => {
    dispatch({ type: "RECRUIT_LISTS_STATUS", payload: false });
    dispatch({ type: "SUBMIT_LIST_STATUS", payload: false });
    if (state.CANDIDATES_SIDE_NAME || state.RECRUIT_SIDE_NAME) {
      if (name === "on") {
        // dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
        dispatch({ type: "RECRUIT_SIDE_STATUS", payload: true });
        dispatch({
          type: "RECRUIT_SIDE_NAME",
          payload: state.RECRUIT_SIDE_NAME,
        });
        recruitConditions();
      } else {
        // dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
        dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: true });
        dispatch({
          type: "CANDIDATES_SIDE_NAME",
          payload: state.CANDIDATES_SIDE_NAME,
        });
        dispatch({ type: "PAGE_NAME", payload: "candidates" });
      }
    } else {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: false });
      dispatch({ type: "CANDIDATES_SIDE_STATUS", payload: false });
      dispatch({ type: "CANDIDATES_SIDE_NAME", payload: "" });
      dispatch({ type: "RECRUIT_SIDE_STATUS", payload: false });
      dispatch({ type: "RECRUIT_SIDE_NAME", payload: "" });
      if (name === "on") {
        recruitConditions();
      }
    }
  };
  const recruitConditions = () => {
    dispatch({ type: "PAGE_NAME", payload: "recruit" });
    if (!state.SELECTED_LIST_ID) {
      dispatch({ type: "CANDIDATES_CONTAINER_STATUS", payload: true });
      dispatch({ type: "RECRUIT_SIDE_STATUS", payload: true });
      dispatch({ type: "RECRUIT_SIDE_NAME", payload: "lists" });
    }
  };
  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes("recruit")) {
      recruitConditions();
    }
  }, []);
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid px-3 align-items-end">
          <Link className="navbar-brand" href="/">
            <img src={logo} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav mx-auto mb-2 mb-lg-0">
              {type === "business" && (
                <>
                  <NavLink className="nav-link" to="/">
                    <span onClick={() => closeSideBar()}>CANDIDATES</span>
                  </NavLink>
                  <NavLink className="nav-link" to="/recruit">
                    <span onClick={() => closeSideBar("on")}>RECRUIT</span>
                  </NavLink>
                  {/* <NavLink
                    className="nav-link concierge-link ms-5 submit-btn"
                    to="/concierge"
                  >
                    CONCIERGE
                  </NavLink> */}
                </>
              )}
              {type === "candidate" && (
                <>
                  {/* <NavLink className="nav-link" to="/">
                    Profile
                  </NavLink> */}
                </>
              )}
              {/* <NavLink className="nav-link" aria-current="page" to="/">
                LOBBY
              </NavLink> */}

              {/* <NavLink className="nav-link" to="/guides">
                GUIDES
              </NavLink> */}
            </div>
            <UserControls type={type} />
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavbarContainer;
