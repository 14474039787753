import React, { useState } from "react";
import "./userModals.scss";
function ContactModal() {
  const [enteredMessage, setEnteredMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const messageChangeHandler = (e) => {
    setEnteredMessage(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const messageData = {
      enteredMessage,
    };
    console.log(messageData);
    resetSubmitHandler();
    setSuccess(true);
  };

  const resetSubmitHandler = (e) => {
    setEnteredMessage("");
    // closeContactModal();
  };
  const closeContactModal = () => {
    const contactModal = document.querySelector(".contact-modal");
    contactModal.classList.remove("active");
    setTimeout(() => {
      setSuccess(false);
    }, 400);
  };

  return (
    <div className="nav_user_modal contact-modal col-3">
      <div className="contact-modal-container">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeContactModal}
        >
          <i className="icon-close"></i>
        </button>
        {success ? (
          <>
            <h4 className="mt-4 bold-text">SENT SUCCESSFULLY!</h4>
            <button
              className="reset-btn submit-btn mt-5 ms-auto d-block normal-text"
              onClick={closeContactModal}
            >
              CLOSE
            </button>
          </>
        ) : (
          <>
            <h2 className="modal-title mb-4 bold-text">CONNECT</h2>
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <label htmlFor="subject" className="form-label normal-text">
                  SUBJECT <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  className="form-control normal-text mb-4"
                />

                <label
                  htmlFor="guest-message"
                  className="form-label normal-text"
                >
                  MESSAGE <span className="required-star">*</span>
                </label>
                <textarea
                  className="form-control normal-text"
                  id="guest-message"
                  rows="6"
                  // placeholder="MESSAGE"
                  value={enteredMessage}
                  onChange={messageChangeHandler}
                  required
                ></textarea>
              </div>
              <div className="d-flex justify-content-between align-items-center profile-control pb-3">
                <input
                  className="reset-btn normal-text"
                  type="button"
                  onClick={() => {
                    resetSubmitHandler();
                    closeContactModal();
                  }}
                  defaultValue="CANCEL"
                />
                <input
                  className="reset-btn submit-btn normal-text"
                  type="submit"
                  value="SEND"
                  onClick={submitHandler}
                />
              </div>
            </form>
            <div className="social-container text-center">
              <a href="#" target="_blank">
                <i className="icon-facebook"></i>
              </a>
              <a href="#" target="_blank">
                <i className="icon-linkedin"></i>
              </a>
              <a href="#" target="_blank">
                <i className="icon-twitter"></i>
              </a>
              <a href="#" target="_blank">
                <i className="icon-instagram"></i>
              </a>
              <a href="#" target="_blank">
                <i className="icon-whatsapp"></i>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ContactModal;
