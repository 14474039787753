import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../AppContext";
import "react-phone-input-2/lib/style.css";
import "./userModals.scss";

function UserProfileModal() {
  const { state } = useAppContext();
  const [logout, setLogout] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [currentType, setCurrentType] = useState("password");
  const [currentIcon, setCurrentIcon] = useState("icon-eye-solid");
  const [newType, setNewType] = useState("password");
  const [newIcon, setNewIcon] = useState("icon-eye-solid");
  const [confirmPassword, setConfirmPassword] = useState(false);
  let navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate("");
    localStorage.clear();
    window.location.reload();
    closeUserModal();
  };
  const closeUserModal = () => {
    const contactModal = document.querySelector(".user-profile-modal");
    contactModal.classList.remove("active");
    setTimeout(() => {
      setLogout(false);
      setChangePassword(false);
      setCurrentType("password");
      setCurrentIcon("icon-eye-solid");
      setNewType("password");
      setNewIcon("icon-eye-solid");
      setConfirmPassword(false);
    }, 400);
  };
  const openLogout = () => {
    setLogout(true);
  };
  const openChangePassword = () => {
    setChangePassword(true);
  };
  const openConfirmPassword = () => {
    console.log("saved New Password");
    setChangePassword(false);
    setConfirmPassword(true);
  };
  const handleCurrentPassword = () => {
    if (currentType === "password") {
      setCurrentIcon("icon-eye-slash-solid");
      setCurrentType("text");
    } else {
      setCurrentIcon("icon-eye-solid");
      setCurrentType("password");
    }
  };
  const handleNewPassword = () => {
    if (newType === "password") {
      setNewIcon("icon-eye-slash-solid");
      setNewType("text");
    } else {
      setNewIcon("icon-eye-solid");
      setNewType("password");
    }
  };

  if (logout) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h4 className="bold-text">LOGOUT, ARE YOU SURE?</h4>
        <div className="d-flex justify-content-between mt-5">
          <button className="reset-btn normal-text" onClick={closeUserModal}>
            CANCEL
          </button>
          <button
            className="reset-btn submit-btn normal-text"
            onClick={navigateToHomePage}
          >
            LOGOUT
          </button>
        </div>
      </div>
    );
  } else if (changePassword) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <p>
          Enter a new password to reset the password on your account. We'll ask
          for this password whenever you log in.
        </p>
        <form>
          <div className="mb-4 d-flex align-items-center">
            <div className="col-10">
              <input
                className="form-control normal-text"
                type={currentType}
                name="password"
                placeholder="CURRENT PASSWORD"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <span className="col-2 text-center" onClick={handleCurrentPassword}>
              <i className={`icon ${currentIcon}`}></i>
            </span>
          </div>
          <div className="mb-4 d-flex align-items-center">
            <div className="col-10">
              <input
                className="form-control normal-text"
                type={newType}
                name="password"
                placeholder="NEW PASSWORD"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <span className="col-2 text-center" onClick={handleNewPassword}>
              <i className={`icon ${newIcon}`}></i>
            </span>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <input
              className="reset-btn mb-0 normal-text"
              type="button"
              value="CANCEL"
              onClick={closeUserModal}
            />
            <input
              className="reset-btn submit-btn mb-0 normal-text"
              type="button"
              value="CHANGE"
              onClick={openConfirmPassword}
            />
          </div>
        </form>
      </div>
    );
  } else if (confirmPassword) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="bold-text">PASSWORD CHANGED!</h2>
        <div className="d-flex justify-content-end mt-5">
          <button
            className="reset-btn submit-btn normal-text"
            onClick={closeUserModal}
          >
            CLOSE
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="modal-title mb-4 text-uppercase bold-text">Account</h2>
        <div className="d-flex justify-content-between align-items-center profile-control pb-3">
          <button
            className="reset-btn mb-0 submit-btn px-2 py-1 text-uppercase normal-text"
            onClick={openChangePassword}
          >
            CHANGE PASSWORD
          </button>
          <button
            className="reset-btn mb-0 red-btn submit-btn px-2 py-1 normal-text"
            onClick={openLogout}
          >
            LOGOUT
          </button>
        </div>
      </div>
    );
  }
}

export default UserProfileModal;
