export const getSiblings = function (e) {
  // for collecting siblings
  let siblings = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;
  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
};

export const sidebarHandler = (side, onClick, savedTitle) => {
  console.log("in");
  const sideBar = document.querySelector(".side-bar");
  const tagsContainer = document.querySelector(".tags-container");
  const savedSearchContainer = document.querySelector(
    ".saved-search-container"
  );
  const openTags = document.querySelector(".open-tags");
  const savedLists = document.querySelector(".saved-lists");

  const listsContainer = document.querySelector(".lists-container");
  const submittedContainer = document.querySelector(".submitted-container");
  const openLists = document.querySelector(".open-lists");
  const submittedLists = document.querySelector(".submitted-lists");

  if (side === sideBar.dataset.sideName) {
    sideBar.dataset.sideName = "";
    sideBar.dataset.status = "false";
    sideBar.classList.remove("animated");
    if (tagsContainer) {
      savedSearchContainer.classList.remove("active");
      tagsContainer.classList.remove("active");
      savedLists.classList.remove("active");
      openTags.classList.remove("active");
      // setBreadcrumb(false);
    } else {
      if (listsContainer) {
        listsContainer.classList.remove("active");
        submittedContainer.classList.remove("active");
        openLists.classList.remove("active");
        submittedLists.classList.remove("active");
      }
    }
    onClick && onClick(false);
    // localStorage.setItem("sidebar-status", "false");
  } else if (side !== sideBar.dataset.sideName) {
    sideBar.dataset.sideName = side;
    sideBar.dataset.status = "true";
    sideBar.classList.add("animated");
    onClick && onClick(true);
    if (side === "tags") {
      tagsContainer.classList.add("active");
      savedSearchContainer.classList.remove("active");
      openTags.classList.add("active");
      savedLists.classList.remove("active");
      // setBreadcrumb(false);
    } else if (side === "saved") {
      savedSearchContainer.classList.add("active");
      tagsContainer.classList.remove("active");
      savedLists.classList.add("active");
      openTags.classList.remove("active");
      const searchNameInput = document.querySelector(".store-search-name");
      savedTitle && savedTitle(searchNameInput.value);
      // setBreadcrumb(true);
    } else if (side === "lists") {
      if (listsContainer) {
        listsContainer.classList.add("active");
        submittedContainer.classList.remove("active");
        openLists.classList.add("active");
        submittedLists.classList.remove("active");
      }
    } else {
      if (listsContainer) {
        listsContainer.classList.remove("active");
        submittedContainer.classList.add("active");
        openLists.classList.remove("active");
        submittedLists.classList.add("active");
      }
    }
    // localStorage.setItem("sidebar-status", "true");
  }
};
export const closeSideBar = (onClick) => {
  // if(sideBar.dataset.status === "false"){}
  const sideBar = document.querySelector(".side-bar");
  const tagsContainer = document.querySelector(".tags-container");
  const savedSearchContainer = document.querySelector(
    ".saved-search-container"
  );
  const openTags = document.querySelector(".open-tags");
  const savedLists = document.querySelector(".saved-lists");

  const listsContainer = document.querySelector(".lists-container");
  const submittedContainer = document.querySelector(".submitted-container");
  const openLists = document.querySelector(".open-lists");
  const submittedLists = document.querySelector(".submitted-lists");

  sideBar.dataset.sideName = "";
  sideBar.dataset.status = "false";
  sideBar.classList.remove("animated");
  if (tagsContainer) {
    savedSearchContainer.classList.remove("active");
    tagsContainer.classList.remove("active");
    savedLists.classList.remove("active");
    openTags.classList.remove("active");
  } else if (listsContainer) {
    listsContainer.classList.remove("active");
    submittedContainer.classList.remove("active");
    openLists.classList.remove("active");
    submittedLists.classList.remove("active");
  }

  // if (onClick) {
  //   onClick(false);
  // }
};
const toggleActiveClass = (e) => {
  const el = e.currentTarget;
  let siblings = getSiblings(el);
  el.classList.remove("active");
  siblings[0].classList.add("active");
  // siblings.map((e) => e.classList.add("active"));
};
export const imageChangeHandler = (type, e) => {
  const candidatesImage = document.querySelectorAll(".candidate-img");
  if (type === "face") {
    candidatesImage.forEach((candidate) => {
      candidate.style.opacity = "0";
      setTimeout(() => {
        candidate.src = candidate.dataset.face;
        candidate.style.opacity = "1";
      }, 500); // Delay the image source change to coincide with the fade-out transition
    });
  } else if (type === "body") {
    candidatesImage.forEach((candidate) => {
      candidate.style.opacity = "0";
      setTimeout(() => {
        candidate.src = candidate.dataset.body;
        candidate.style.opacity = "1";
      }, 500); // Delay the image source change to coincide with the fade-out transition
    });
  } else {
    return;
  }
  toggleActiveClass(e);
};

export const pushOrRemoveFromArray = (arr, value) => {
  const index = arr.indexOf(value);

  if (index === -1) {
    // Value doesn't exist in the array, so push it
    arr.push(value);
  } else {
    // Value already exists in the array, so remove it
    arr.splice(index, 1);
  }
};

export const isLastCharacterAmpersand = (str) => {
  // Use trim() to remove leading and trailing whitespaces (if any)
  const trimmedStr = str.trim();

  // Check if the last character is an ampersand
  return trimmedStr.charAt(trimmedStr.length - 1) === "&";
};

export const convertDateFormat = (inputDate) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObject = new Date(inputDate);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${months[monthIndex]} ${year}`;

  return formattedDate;
};

export const convertDateFormatShort = (inputDate) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const dateObject = new Date(inputDate);
  const day = String(dateObject.getDate()).padStart(2, "0");
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${months[monthIndex]} ${year}`;

  return formattedDate;
};
