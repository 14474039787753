import React from "react";

import Home from "./components/Home/Home";
import Business from "./components/Business/Business";
import Candidate from "./components/Candidate/Candidate";

import "./App.scss";
function App() {
  const loggedin = localStorage.getItem("LoggedIn");
  const userRole = localStorage.getItem("USER_ROLE");
  return (
    <div className="App">
      {!loggedin || loggedin === "false" ? <Home /> : null}
      {userRole === "business" && <Business />}
      {userRole === "candicate" && <Candidate />}
    </div>
  );
}

export default App;
