import axios from "axios";

export const DeleteFromListReq = async (
  url,
  candidateId,
  listID,
  selectedEl,
  updateLists
) => {
  try {
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    if (!listID) {
      alert("Please select a list to add the candidate.");
      return; // Exit early if no list is selected
    }

    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };

    const data = {
      list_id: listID,
      candidate_id: candidateId,
    };

    const response = await axios.post(url, data, {
      headers: customHeaders,
    });

    // Check response status and handle accordingly
    if (response.status === 200) {
      selectedEl.classList.remove("selected");
      updateLists && updateLists();
      console.log("Successfully removed candidate from the list");
      return response.data; // Return data if needed
    } else {
      console.error("Unexpected response status:", response.status);
      // Handle other response statuses if needed
    }
  } catch (error) {
    console.error("Error sending data to the backend:", error);
    // throw error; // You can handle the error in the calling component
  }
};
