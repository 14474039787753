import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./userModals.scss";

function UserProfileModal() {
  const { state } = useAppContext();
  const notificationsUrl = `${state.HTTP_URL}/notifications`;
  const [userInfo, setUserInfo] = useState({});
  const [logout, setLogout] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [currentType, setCurrentType] = useState("password");
  const [currentIcon, setCurrentIcon] = useState("icon-eye-solid");
  const [newType, setNewType] = useState("password");
  const [newIcon, setNewIcon] = useState("icon-eye-solid");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [phone, setPhone] = useState("");
  let navigate = useNavigate();
  // useEffect(() => {}, [logout, editProfile]);
  const navigateToHomePage = () => {
    navigate("");
    localStorage.clear();
    window.location.reload();
    closeUserModal();
  };
  const closeUserModal = () => {
    const contactModal = document.querySelector(".user-profile-modal");
    contactModal.classList.remove("active");
    setTimeout(() => {
      setLogout(false);
      setEditProfile(false);
      setChangePassword(false);
      setCurrentType("password");
      setCurrentIcon("icon-eye-solid");
      setNewType("password");
      setNewIcon("icon-eye-solid");
      setConfirmPassword(false);
      setChangeEmail(false);
      setConfirmEmail(false);
    }, 400);
  };
  const openLogout = () => {
    setLogout(true);
  };
  const openEditProfile = () => {
    setEditProfile(true);
  };
  const openChangePassword = () => {
    setEditProfile(false);
    setChangePassword(true);
  };
  const openConfirmPassword = () => {
    console.log("saved New Password");
    setEditProfile(false);
    setChangePassword(false);
    setConfirmPassword(true);
  };
  const openConfirmEmail = () => {
    console.log("saved New Email");
    setEditProfile(false);
    setChangeEmail(false);
    setConfirmEmail(true);
  };
  const openChangeEmail = (e) => {
    setEditProfile(false);
    setChangeEmail(true);
  };
  const saveProfileDetails = (e) => {
    e.preventDefault();
    console.log("saved Profile Details");
    closeUserModal();
  };
  const handleCurrentPassword = () => {
    if (currentType === "password") {
      setCurrentIcon("icon-eye-slash-solid");
      setCurrentType("text");
    } else {
      setCurrentIcon("icon-eye-solid");
      setCurrentType("password");
    }
  };
  const handleNewPassword = () => {
    if (newType === "password") {
      setNewIcon("icon-eye-slash-solid");
      setNewType("text");
    } else {
      setNewIcon("icon-eye-solid");
      setNewType("password");
    }
  };
  // console.log(editProfile);

  useEffect(() => {
    const url = `${state.HTTP_URL}/business_profile`;
    const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
    // Define your custom headers here
    const customHeaders = {
      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
    };
    // Define an async function to fetch the data
    async function getUserInfo() {
      try {
        const response = await axios.post(
          url,
          {},
          {
            headers: customHeaders,
          }
        );
        setUserInfo(response.data.data);
        // console.log(response.data.data);
      } catch (error) {
        console.error("Error sending data to the backend:", error);
        throw error; // You can handle the error in the calling component
      }
    }
    getUserInfo();
  }, []);

  if (logout) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h4 className="bold-text">LOGOUT, ARE YOU SURE?</h4>
        <div className="d-flex justify-content-between mt-5">
          <button className="reset-btn normal-text" onClick={closeUserModal}>
            CANCEL
          </button>
          <button
            className="reset-btn submit-btn normal-text"
            onClick={navigateToHomePage}
          >
            LOGOUT
          </button>
        </div>
      </div>
    );
  } else if (editProfile) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="modal-title mb-4 text-uppercase bold-text">
          Edit Profile
        </h2>
        <div className="d-flex justify-content-between align-items-center profile-control">
          <button
            className="reset-btn mb-0 submit-btn px-2 py-1 normal-text"
            onClick={openChangePassword}
          >
            CHANGE PASSWORD
          </button>
          <button
            className="reset-btn mb-0 red-btn submit-btn px-2 py-1 normal-text"
            onClick={openLogout}
          >
            LOGOUT
          </button>
        </div>
        <hr className="mb-4" />
        <form>
          <div className="mb-3">
            <label
              htmlFor="jobTitle"
              className="form-label text-uppercase normal-text"
            >
              JOB TITLE
            </label>
            <input
              type="text"
              className="form-control normal-text"
              id="jobTitle"
              placeholder="JOB TITLE"
              required=""
              defaultValue=""
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="form-label text-uppercase normal-text"
            >
              Phone
            </label>
            <PhoneInput
              country={"eg"}
              className="w-100 bold-text"
              enableSearch={true}
              value={phone}
              id="phone"
              onChange={(phone) => setPhone(phone)}
            />
          </div>
          <div className="d-flex align-items-center profile-control">
            <div className="col-9">
              <input
                type="email"
                className="form-control normal-text"
                id="email"
                placeholder="email@company.com"
                disabled={true}
                defaultValue=""
              />
            </div>
            <input
              className="reset-btn mb-0 col-3 normal-text"
              type="button"
              value="CHANGE"
              onClick={openChangeEmail}
            />
          </div>
        </form>
        <hr className="mt-5" />
        <div className="d-flex justify-content-between mt-5">
          <button className="reset-btn normal-text" onClick={closeUserModal}>
            CANCEL
          </button>
          <input
            className="reset-btn submit-btn mb-0 normal-text"
            type="button"
            value="SAVE"
            onClick={saveProfileDetails}
          />
        </div>
      </div>
    );
  } else if (changePassword) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <p>
          Enter a new password to reset the password on your account. We'll ask
          for this password whenever you log in.
        </p>
        <form>
          <div className="mb-4 d-flex align-items-center">
            <div className="col-10">
              <input
                className="form-control normal-text"
                type={currentType}
                name="password"
                placeholder="CURRENT PASSWORD"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <span className="col-2 text-center" onClick={handleCurrentPassword}>
              <i className={`icon ${currentIcon}`}></i>
            </span>
          </div>
          <div className="mb-4 d-flex align-items-center">
            <div className="col-10">
              <input
                className="form-control normal-text"
                type={newType}
                name="password"
                placeholder="NEW PASSWORD"
                // value={password}
                // onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <span className="col-2 text-center" onClick={handleNewPassword}>
              <i className={`icon ${newIcon}`}></i>
            </span>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <input
              className="reset-btn mb-0 normal-text"
              type="button"
              value="CANCEL"
              onClick={closeUserModal}
            />
            <input
              className="reset-btn submit-btn mb-0 normal-text"
              type="button"
              value="CHANGE"
              onClick={openConfirmPassword}
            />
          </div>
        </form>
      </div>
    );
  } else if (confirmPassword) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="bold-text">PASSWORD CHANGED!</h2>
        <div className="d-flex justify-content-end mt-5">
          <button
            className="reset-btn submit-btn normal-text"
            onClick={closeUserModal}
          >
            CLOSE
          </button>
        </div>
      </div>
    );
  } else if (changeEmail) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <p>
          We'll send an email to email@website.com with a verification link to
          your new email address. You'll need to open the email and click the
          link.
        </p>
        <form>
          <div className="mb-4 d-flex align-items-center">
            <div className="col-10">
              <input
                className="form-control normal-text"
                type="email"
                name="email"
                placeholder="NEW EMAIL ADDRESS"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <input
              className="reset-btn mb-0 normal-text"
              type="button"
              value="CANCEL"
              onClick={closeUserModal}
            />
            <input
              className="reset-btn submit-btn mb-0 normal-text"
              type="button"
              value="CHANGE"
              onClick={openConfirmEmail}
            />
          </div>
        </form>
      </div>
    );
  } else if (confirmEmail) {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="bold-text">EMAIL CHANGED!</h2>
        <div className="d-flex justify-content-end mt-5">
          <button
            className="reset-btn submit-btn normal-text"
            onClick={closeUserModal}
          >
            CLOSE
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nav_user_modal user-profile-modal col-3">
        <button
          className="reset-btn close-modal position-static mb-4 ms-auto d-block normal-text"
          onClick={closeUserModal}
        >
          <i className="icon-close"></i>
        </button>
        <h2 className="modal-title mb-4 text-uppercase bold-text">Account</h2>
        <div className="d-flex justify-content-between align-items-center profile-control pb-3">
          <button
            className="reset-btn mb-0 submit-btn px-2 py-1 text-uppercase normal-text"
            onClick={openEditProfile}
          >
            EDIT PROFILE
          </button>
          <button
            className="reset-btn mb-0 red-btn submit-btn px-2 py-1 normal-text"
            onClick={openLogout}
          >
            LOGOUT
          </button>
        </div>
        <hr className="mt-1 mb-4" />
        <div className="profile-info">
          <h3 className="user-name mb-3 bold-text text-capitalize">
            {userInfo.name}
          </h3>
          <div className="user-data user-job text-uppercase mb-2 normal-text">
            HR EXECUTIVE, IHG GROUP DUBAI
          </div>
          <div className="user-data user-location text-uppercase mb-2 normal-text">
            DUBAI, UNITED ARAB EMIRATES
          </div>
          <div className="user-data user-email text-uppercase mb-2 normal-text">
            {userInfo.email}
          </div>
          <div className="user-data user-phone text-uppercase mb-2 normal-text">
            +20 10 1234 5678
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfileModal;
