import React from "react";
import { getSiblings, convertDateFormatShort } from "../../../helpers/helpers";
import { useAppContext } from "../../../AppContext";
import axios from "axios";
import "./ItemGrid.scss";

function ItemGrid(props) {
  const { state, dispatch } = useAppContext();
  const recruitCandidatesList = state.RECRUIT_CANDIDATES_LIST;
  const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
  const USER_ID = localStorage.getItem("USER_ID");
  // Define your custom headers here
  const customHeaders = {
    Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
  };
  const selectListItemHandler = (e, activeList, title) => {
    toggleActiveClass(e);
    props.setMoveAll && props.setMoveAll();
    props.clicked && props.clicked(activeList);
    props.choosenList && props.choosenList(activeList);
    props.addNewList && props.addNewList();
    props.favorites && props.favorites();
    !props.updating && updateCandidateList(activeList);
    props.updating && updateRecruitCandidateList(props.id, title);
    props.updating && props.onSelectList();
    props.updating &&
      dispatch({
        type: "SUBMIT_LIST_BTN",
        payload: false,
      });
  };
  const toggleActiveClass = (e) => {
    let el;
    e.currentTarget ? (el = e.currentTarget) : (el = e);
    let siblings = getSiblings(el);
    el.classList.add("active");
    siblings.map((e) => e.classList.remove("active"));
  };

  const countIDealLength = (array) => {
    const filteredArray = array.filter(
      (obj) => obj.business_status === "ideal"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const countIMaybeLength = (array) => {
    const filteredArray = array.filter(
      (obj) =>
        obj.business_status === "maybe" || obj.business_status === "pending"
    );

    // Return the count of filtered objects
    return filteredArray.length;
  };
  const updateRecruitCandidateList = (id, listName) => {
    const url = `${state.HTTP_URL}/list_candicates/${id}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url, {
          headers: customHeaders,
        });
        if (response.status === 200) {
          // console.log(response.data.data);
          let candidatesInList = [];

          response.data.data.map((item) =>
            candidatesInList.push(item.candidate)
          );

          const countIMaybe = countIMaybeLength(candidatesInList);
          const countIDeal = countIDealLength(candidatesInList);
          // Dispatch action to update RECRUIT_CANDIDATES_LIST
          dispatch({
            type: "RECRUIT_CANDIDATES_LIST",
            payload: candidatesInList,
          });
          dispatch({
            type: "SELECTED_LIST_ID",
            payload: id,
          });
          dispatch({
            type: "SELECTED_LIST_NAME",
            payload: listName,
          });
          dispatch({ type: "TAB_LIST_NAME", payload: "pending-list" });
          dispatch({
            type: "ALL_LIST_LENGTH",
            payload: candidatesInList.length,
          });
          dispatch({ type: "MAYBE_LIST_LENGTH", payload: countIMaybe });
          dispatch({ type: "IDEAL_LIST_LENGTH", payload: countIDeal });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  };
  const updateCandidateList = (activeList) => {
    console.log(activeList);
    const url = `${state.HTTP_URL}/candidate/all?list_id=${activeList}&user_id=${USER_ID}`;
    // Define an async function to fetch the data
    async function fetchData() {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          // Dispatch action to update CANDIDATES_LIST
          dispatch({ type: "CANDIDATES_LIST", payload: response.data.data });
          console.log(response.data.data);
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        // setLoading(false);
      }
    }

    fetchData();
  };

  return (
    <div
      className={`item-grid-container ${
        props.id === state.ACTIVE_LIST_ID && state.PAGE_NAME === "candidates"
          ? "active"
          : ""
      } ${
        props.id === state.SELECTED_LIST_ID && state.PAGE_NAME === "recruit"
          ? "active"
          : ""
      }`}
      data-id={props.id}
      onClick={(e) => selectListItemHandler(e, props.id, props.title)}
    >
      <div className="d-flex align-items-center justify-content-between mt-2 p-1">
        <div className="title normal-text">
          {`${props.title} ${props.count ? `(${props.count})` : ""}`}
        </div>
        {props.date && (
          <div className="date normal-text">
            {convertDateFormatShort(props.date)}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemGrid;
