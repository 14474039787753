import React from "react";
import "./PersonalInfo.scss";
function PersonalInfo({ data, type }) {
  return (
    <>
      {data && (
        <>
          <div className="candidate-data" key={0}>
            <div className="d-flex justify-content-between">
              <div className="candidate-personal-info">
                <span className="candidate-name text-uppercase bold-text">
                  <b>{data.first_name}</b>
                </span>
                ,
                <span className="candidate-age mx-1 normal-text">
                  {data.age}
                </span>
                <span className="candidate-gender mx-1">
                  {data.gender === "male" ? (
                    <i className="icon-male-sign"></i>
                  ) : (
                    <i className="icon-female-sign"></i>
                  )}
                </span>
                <div className="candidate-location normal-text">
                  <span className="candidate-city text-uppercase normal-text">
                    {data.city_name}
                  </span>
                  ,
                  <span className="candidate-country text-uppercase mx-1">
                    {data.country_name}
                  </span>
                </div>
              </div>
              {type !== "candidate" && (
                <div className="candidate-status">
                  <span className="candidate-availability available text-uppercase normal-text">
                    available
                  </span>
                </div>
              )}
            </div>
            {type !== "candidate" && (
              <>
                {data.disability !== "no" ? (
                  <div className="disability row m-0 mt-3">
                    <div className="col-1 p-0">
                      <i className="icon-disability"></i>
                    </div>
                    <div className="col-11 p-0">
                      <p>{data.disability}</p>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <hr />
        </>
      )}
    </>
  );
}

export default PersonalInfo;
