import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppContext } from "../../../../AppContext";
// select2
import "select2/dist/css/select2.min.css";
import $ from "jquery"; // Select2 relies on jQuery
import "select2";
import "./EditContact.scss";
const EditContact = ({ oncloseModal }) => {
  const { state, dispatch } = useAppContext();
  // states
  const [country, setCountry] = useState(
    state.CANDIDATE_PROFILE.country_id || "All"
  );
  const [city, setCity] = useState(state.CANDIDATE_PROFILE.city_id || "All");
  const [countryCode, setCountryCode] = useState("+20");
  const [enableEmail, setEnableEmail] = useState(true);
  const [countriesArr, setCountriesArr] = useState([]);
  const [citesList, setCitesList] = useState([]);
  const [phone1, setPhone1] = useState(state.CANDIDATE_PROFILE.phone1 || "");
  const [phone2, setPhone2] = useState(state.CANDIDATE_PROFILE.phone2 || "");
  const [email, setEmail] = useState(state.CANDIDATE_PROFILE.email || "");

  // refs
  const countrySelectRef = useRef(null);
  const citySelectRef = useRef(null);
  // functions
  const isFormValid = () => {
    return (
      country.trim() !== "" &&
      city.trim() !== "" &&
      phone1.trim() !== "" &&
      phone2.trim() !== "" &&
      email.trim() !== ""
    );
  };
  const saveContactHandler = () => {
    if (isFormValid()) {
      // Collect the values and perform further actions
      const contactData = {
        country_id: country,
        city_id: city,
        phone1,
        phone2,
        email,
      };
      // post request
      const YOUR_ACCESS_TOKEN = localStorage.getItem("YOUR_ACCESS_TOKEN");
      // Define your custom headers here
      const customHeaders = {
        Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,
      };
      const url = `${state.HTTP_URL}/candidate_update_profile_contact`;
      axios
        .post(url, contactData, {
          headers: customHeaders,
        })
        .then((response) => {
          console.log(response.data.data);
          oncloseModal();
          const url = `${state.HTTP_URL}/candidate_profile`;
          axios
            .post(
              url,
              {},
              {
                headers: customHeaders,
              }
            )
            .then((response) => {
              dispatch({
                type: "CANDIDATE_PROFILE",
                payload: response.data.data,
              });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      alert("Please fill out all fields before saving.");
    }
  };

  // get countries list
  useEffect(() => {
    // Fetch countries from the API
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${state.HTTP_URL}/countries`);
        const countries = response.data.data;
        setCountriesArr(countries);

        // Set default cities based on the first country (you may adjust as needed)
        setCitesList(countries[0].cities);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
    fetchCities(state.CANDIDATE_PROFILE.country_id);
  }, []);
  const toggleEnableEmail = () => {
    setEnableEmail(false);
  };
  // show cities
  let citesListContent;
  if (citesList) {
    citesListContent = citesList.map((city, index) => (
      <option value={city.id} key={city.id}>
        {city.name}
      </option>
    ));
  }
  const fetchCities = async (countryId) => {
    try {
      // Assuming your API endpoint to fetch cities takes the country ID as a parameter
      const response = await axios.get(`${state.HTTP_URL}/cites/${countryId}`);
      const cities = response.data.data;
      setCitesList(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  // Select2 init
  useEffect(() => {
    $(countrySelectRef.current).select2();
    $(citySelectRef.current).select2();

    $(countrySelectRef.current).on("change", async (e) => {
      const selectedCountryId = e.target.value;

      if (selectedCountryId !== "All") {
        await fetchCities(selectedCountryId);
      } else {
        setCitesList(false);
      }

      setCountry(selectedCountryId);
    });

    $(citySelectRef.current).on("change", (e) => {
      setCity(e.target.value);
    });

    return () => {
      $(countrySelectRef.current).select2("destroy");
      $(citySelectRef.current).select2("destroy");
    };
  }, []);

  // Show Countries list names
  const countriesList = countriesArr.map((country) => (
    <option value={country.id} key={country.id}>
      {country.name}
    </option>
  ));

  return (
    <div className="edit-contact">
      <p className="normal-text text-uppercase red-text">
        Disclaimer: Phone Number and Country need to match
      </p>
      <form className="mt-4">
        <div className="select-control mb-2 col-6">
          <select
            id="country-selection"
            name="country-selection"
            ref={countrySelectRef}
            value={country}
          >
            {/* Add your options here */}
            <option value="All">Country</option>
            {countriesList}
          </select>
        </div>
        <div className={`select-control mb-2 col-6`}>
          <select
            id="city-selection"
            name="city-selection"
            ref={citySelectRef}
            value={city}
          >
            {/* Add your options here */}
            <option value="All">City</option>
            {citesListContent && citesListContent}
          </select>
        </div>
        <div className="d-flex country-code-container mb-4">
          <span className="country-code md-font">{countryCode}</span>
          <input
            type="tel"
            name="countryCode"
            defaultValue={phone1}
            onChange={(e) => setPhone1(e.target.value)}
            className="country-number call-number"
          />
          <span className="call-icon">
            <i className="icon-whatsapp"></i>
          </span>
        </div>
        <div className="d-flex country-code-container mb-4">
          <span className="country-code md-font">{countryCode}</span>
          <input
            type="tel"
            name="countryCode"
            defaultValue={phone2}
            onChange={(e) => setPhone2(e.target.value)}
            className="country-number whatsapp-number"
          />
          <span className="whatsapp-icon">
            <i className="icon-whatsapp"></i>
          </span>
        </div>

        <div className="d-flex align-items-center user-email-container mb-5">
          <input
            type="email"
            name="user-email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            className="user-email"
            disabled={enableEmail}
          />
          <span
            className="country-code md-font ms-3 pointer"
            onClick={toggleEnableEmail}
          >
            Change
          </span>
        </div>
      </form>

      <div className="d-flex justify-content-between edit-profile-action-btns edit-education">
        <button className="reset-btn normal-text" onClick={oncloseModal}>
          CANCEL
        </button>
        <button
          className="reset-btn submit-btn normal-text md-font"
          onClick={saveContactHandler}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default EditContact;
