import Navbar from "../Navbar/Navbar";
import Lobby from "../Lobby/Lobby";
import CandidateRecruitCard from "./CandidateRecruitCard/CandidateRecruitCard";
import PageNotFound from "../Shared/PageNotFound/PageNotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import "./App.scss";

function Business() {
  return (
    <div className="dashborad">
      <Router>
        <Navbar type="business" />
        <Routes>
          {/* <Route exact path="/" element={<Lobby />} /> */}
          <Route
            exact
            // path="/candidates"
            path="/"
            element={<CandidateRecruitCard type="candidates" />}
          />
          <Route
            exact
            path="/recruit"
            element={<CandidateRecruitCard type="recruit" />}
          />
          {/* <Route exact path="/guides" element={<p>Guides Page</p>} /> */}
          <Route exact path="/concierge" element={<p>Concierge Page</p>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default Business;
