import React, { useState } from "react";
import axios from "axios";
import { useAppContext } from "../../../AppContext";
import "./ShowCandidate.scss";
function ShowCandidate(props) {
  let videoChecker;
  if (localStorage.getItem("isVideo") === "true") {
    videoChecker = true;
  } else {
    videoChecker = false;
  }
  const { state } = useAppContext();
  const [isVideo, setIsVideo] = useState(videoChecker);

  // change display src
  const serDisplaySrc = (type, e) => {
    if (type === "video") {
      setIsVideo(true);
    } else {
      setIsVideo(false);
      console.log(e.target);
      setTimeout(() => {
        document.querySelector(".full-image").src = e.target.dataset.src;
      }, 100);
    }
  };
  return (
    <>
      {props.data && (
        <div className="col-9 images-container justify-content-center h-100 d-flex">
          <div className="main-side text-center display-item d-flex align-items-center h-100">
            <i className="icon-checkmark selectedItemInModal"></i>
            {!isVideo ? (
              <img
                className="full-image"
                src={props.data.photo2}
                alt="candidate Person"
              />
            ) : (
              <iframe
                className="full-video"
                width="528"
                height="315"
                src={props.data.video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <div className="thumbnails-side">
            <div className="d-flex m-0 flex-column align-items-center justify-content-center h-100">
              <div className="mb-3 p-0">
                <img
                  src={props.data.photo2}
                  data-src={props.data.photo2}
                  alt="candidate fullbody"
                  onClick={(e) => serDisplaySrc(null, e)}
                />
              </div>
              <div className="mb-3 p-0">
                <img
                  src={props.data.photo1}
                  data-src={props.data.photo1}
                  alt="candidate face"
                  onClick={(e) => serDisplaySrc(null, e)}
                />
              </div>
              <div className="mb-3 p-0">
                <img
                  src={props.data.photo3}
                  data-src={props.data.photo3}
                  alt="candidate side"
                  onClick={(e) => serDisplaySrc(null, e)}
                />
              </div>
              <div
                className="p-0 video-item"
                onClick={(e) => serDisplaySrc("video")}
              >
                <i className="icon-circle-play-regular"></i>
                <img src={props.data.photo1.video} alt="video" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShowCandidate;
