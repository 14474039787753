import React, { createContext, useContext, useReducer, useEffect } from "react";

const initialState = {
  CANDIDATES_SIDE_NAME: "tags",
  CANDIDATES_SIDE_STATUS: false,
  RECRUIT_SIDE_NAME: "lists",
  RECRUIT_SIDE_STATUS: false,
  CANDIDATES_CONTAINER_STATUS: false,
  RECRUIT_LISTS_STATUS: false,
  OVERLAY_RECRUIT_LISTS_STATUS: false,
  SUBMIT_LIST_STATUS: false,
  HTTP_URL: "https://dashboard.starlinestepper.com/api",
  ACCESS_TOKEN: localStorage.getItem("YOUR_ACCESS_TOKEN"),
  LISTS_LIST: [],
  SAVED_SEARCH_NAME: "Untitled Search",
  SAVED_SEARCH_STATUS: false,
  PAGE_NAME: "candidates",
  SAVED_SEARCH_LINK: "",
  CANDIDATES_IDS: [],
  CANDIDATES_LIST: [],
  RECRUIT_CANDIDATES_LIST: [],
  ALL_LIST_LENGTH: 0,
  MAYBE_LIST_LENGTH: 0,
  IDEAL_LIST_LENGTH: 0,
  ACTIVE_LIST_ID: null,
  ACTIVE_LIST_NAME: null,
  SELECTED_LIST_ID: null,
  SELECTED_LIST_NAME: "",
  SUBMITED_LIST_INFO: [],
  SUBMIT_LIST_BTN: false,
  MOVE_ALL_BTN: false,
  SUBMITTED_LISTS_LIST: [],
  IDEAL_LIST_IDS: [],
  RECRUIT_ACTIVE_LIST_ID: null,
  NO_RESULTS_MASSAGE: "",
  SEARCH_PARAMS: "",
  SAVED_SEARCH_LIST: [],
  TAB_LIST_NAME: "",
  CANDIDATE_PROFILE: "", // CANDIDATE_PROFILE Object
};
const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    localStorage.setItem("HTTP_URL", state.HTTP_URL);
    localStorage.setItem("ACCESS_TOKEN", state.ACCESS_TOKEN);
    localStorage.setItem("CANDIDATES_IDS", state.CANDIDATES_IDS);
  }, [state.HTTP_URL, state.ACCESS_TOKEN, state.CANDIDATES_IDS]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "CANDIDATES_SIDE_NAME":
      return { ...state, CANDIDATES_SIDE_NAME: action.payload };
    case "CANDIDATES_SIDE_STATUS":
      return { ...state, CANDIDATES_SIDE_STATUS: action.payload };
    case "RECRUIT_SIDE_NAME":
      return { ...state, RECRUIT_SIDE_NAME: action.payload };
    case "RECRUIT_SIDE_STATUS":
      return { ...state, RECRUIT_SIDE_STATUS: action.payload };
    case "CANDIDATES_CONTAINER_STATUS":
      return { ...state, CANDIDATES_CONTAINER_STATUS: action.payload };
    case "RECRUIT_LISTS_STATUS":
      return { ...state, RECRUIT_LISTS_STATUS: action.payload };
    case "OVERLAY_RECRUIT_LISTS_STATUS":
      return { ...state, OVERLAY_RECRUIT_LISTS_STATUS: action.payload };
    case "SUBMIT_LIST_STATUS":
      return { ...state, SUBMIT_LIST_STATUS: action.payload };
    case "HTTP_URL":
      return { ...state, HTTP_URL: action.payload };
    case "ACCESS_TOKEN":
      return { ...state, ACCESS_TOKEN: action.payload };
    case "NO_RESULTS_MASSAGE":
      return { ...state, NO_RESULTS_MASSAGE: action.payload };
    case "CANDIDATES_IDS":
      return { ...state, CANDIDATES_IDS: action.payload };
    case "CANDIDATES_LIST":
      return { ...state, CANDIDATES_LIST: action.payload };
    case "RECRUIT_CANDIDATES_LIST":
      return { ...state, RECRUIT_CANDIDATES_LIST: action.payload };
    case "LISTS_LIST":
      return { ...state, LISTS_LIST: action.payload };
    case "ACTIVE_LIST_NAME":
      return { ...state, ACTIVE_LIST_NAME: action.payload };
    case "ACTIVE_LIST_ID":
      return { ...state, ACTIVE_LIST_ID: action.payload };
    case "SELECTED_LIST_NAME":
      return { ...state, SELECTED_LIST_NAME: action.payload };
    case "SUBMITED_LIST_INFO":
      return { ...state, SUBMITED_LIST_INFO: action.payload };
    case "SUBMIT_LIST_BTN":
      return { ...state, SUBMIT_LIST_BTN: action.payload };
    case "MOVE_ALL_BTN":
      return { ...state, MOVE_ALL_BTN: action.payload };
    case "SUBMITTED_LISTS_LIST":
      return { ...state, SUBMITTED_LISTS_LIST: action.payload };
    case "IDEAL_LIST_IDS":
      return { ...state, IDEAL_LIST_IDS: action.payload };
    case "SELECTED_LIST_ID":
      return { ...state, SELECTED_LIST_ID: action.payload };
    case "RECRUIT_ACTIVE_LIST_ID":
      return { ...state, RECRUIT_ACTIVE_LIST_ID: action.payload };
    case "SAVED_SEARCH_LIST":
      return { ...state, SAVED_SEARCH_LIST: action.payload };
    case "SEARCH_PARAMS":
      return { ...state, SEARCH_PARAMS: action.payload };
    case "TAB_LIST_NAME":
      return { ...state, TAB_LIST_NAME: action.payload };
    case "ALL_LIST_LENGTH":
      return { ...state, ALL_LIST_LENGTH: action.payload };
    case "MAYBE_LIST_LENGTH":
      return { ...state, MAYBE_LIST_LENGTH: action.payload };
    case "IDEAL_LIST_LENGTH":
      return { ...state, IDEAL_LIST_LENGTH: action.payload };
    case "SAVED_SEARCH_STATUS":
      return { ...state, SAVED_SEARCH_STATUS: action.payload };
    case "PAGE_NAME":
      return { ...state, PAGE_NAME: action.payload };
    case "SAVED_SEARCH_NAME":
      return { ...state, SAVED_SEARCH_NAME: action.payload };
    case "CANDIDATE_PROFILE":
      return { ...state, CANDIDATE_PROFILE: action.payload };
    case "SAVED_SEARCH_LINK":
      const newSearchLink = updateSavedSearchLink(
        state.SAVED_SEARCH_LINK,
        action.payload
      );
      return { ...state, SAVED_SEARCH_LINK: newSearchLink };
    default:
      return state;
  }
};
// Helper function to update the SAVED_SEARCH_LINK
const updateSavedSearchLink = (currentSearchLink, valueToAdd) => {
  const searchParams = new URLSearchParams(currentSearchLink);

  // Remove existing values to avoid duplicates
  searchParams.delete(valueToAdd.key);

  // Add the new value
  if (valueToAdd.value !== undefined) {
    searchParams.append(valueToAdd.key, valueToAdd.value);
  }

  // Convert URL-encoded characters back to square brackets
  const updatedLink = searchParams
    .toString()
    .replace(/%5B/g, "[")
    .replace(/%5D/g, "]");

  return updatedLink;
};
export default AppProvider;
